import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-with-progress',
  templateUrl: './card-with-progress.component.html',
  styleUrls: ['./card-with-progress.component.scss'],
})
export class CardWithProgressComponent implements OnInit {

  @Input()continue=true;
  @Input()progress=0;
  @Input()course='';
  @Input()content='';
  @Input()time='';
  @Input()imageURL='';
  @Input()selected=true;
  @Input()locked=true;
  @Input()loading=false;



  constructor() { }
  get computedWidth(): string {
    return `calc(${this.progress}% - 25px)`;
  }

  ngOnInit() {}

}
