
import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-content-not-available',
  templateUrl: './content-not-available.component.html',
  styleUrls: ['./content-not-available.component.scss'],
})
export class ContentNotAvailableComponent {

  constructor(private modalCtrl: ModalController) {}

  dismissModal() {
    this.modalCtrl.dismiss();
  }

}
