import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs.page';
import {ProfilePage} from './profile/profile.page';
import {PlayerPage} from '../pages/player/player.page';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: TabsPage,
    children: [
      {
        path: 'courses',
        // component: CoursesPage
        loadChildren: () =>
          import('./courses/courses.module').then((m) => m.CoursesPageModule),
      },
      {
        path: 'player',
        // component: PlayerPage,
        loadChildren: () =>
          import('../pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfilePageModule),
      },

      // {
      //   path: 'subscribe',
      //   loadChildren: () => import('../modal/subscribe/subscribe.module').then(m => m.SubscribeModule)
      // },
      {
        path: 'subscribe',
        loadChildren: () =>
          import('../modal/content_not_available/content-not-available.module').then(
            (m) => m.ContentNotAvailableModule
          ),
      },
      {
        path: '',
        redirectTo: '/courses',
        pathMatch: 'full',
      },
    ],
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class TabsPageRoutingModule {}
