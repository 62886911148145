import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertController, LoadingController} from '@ionic/angular';
import {AuthenticationService} from '../../services/authentication.service';
import {MismatchValidator} from '../../forms/validator/MismatchValidator';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
})
export class RecoverComponent implements OnInit {

  public recoverForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private loadingController: LoadingController,
    private authService: AuthenticationService,
    private alertController: AlertController,
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  async submit() {
    const loading = await this.loadingController.create();
    await loading.present();

    this.authService.recoverPasswordToken(this.route.snapshot.paramMap.get('token'),this.recoverForm.value).subscribe(
      () => {
        loading.dismiss();
        this.router.navigate(['/login']);
      },
      async error => {
        loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Error',
          message: error.error.message,
          buttons: ['OK']
        });
        await alert.present();
      }
    );
  }

  main() {
    this.router.navigate(['/main']);
  }

  private initForm() {
    this.recoverForm = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,30}$/)]],
      passwordConfirmation: ['', Validators.required]
    });

    this.recoverForm.get('passwordConfirmation').setValidators(
      Validators.compose([
        Validators.required,
        MismatchValidator.mismatch(this.recoverForm.get('password'))
      ])
    );
  }

}
