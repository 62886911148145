export class StateContent{


  contentId: string;
  ended: boolean = false;
  seconds: number = 0;
  dateLast: Date;

  constructor(contentId: string, ended: boolean = false, dateLast = null, seconds: number = 0){
    this.contentId = contentId;
    this.ended = ended;
    this.seconds = seconds;

    if(dateLast != null){
      this.dateLast = new Date(dateLast);
    }
  }

}
