import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EHButtonComponent } from './eh-button/eh-button.component';

import { EhCustomInput } from './eh-custom-input/eh-custom-input.component';

import { SocialSignInComponent } from './social-signin/social-sign-in.component';
import { ChatCardComponent } from './chat-card/chat-card.component';
import { SimpleSliderComponent } from './simple-slider/simple-slider.component';
import { ContentCardComponent } from './content-card/content-card.component';
import { CourseCardComponent } from './course-card/course-card.component';
import { ContinueAudioCardComponent } from './continue-audio-card/continue-audio-card.component';
import { SimpleSquareCardComponent } from './simple-square-card/simple-square-card.component';
import { EhCustomPasswordInputComponent } from './eh-custom-password-input/eh-custom-password-input.component';
import { CardWithProgressComponent } from './card-with-progress/card-with-progress.component';
import { ProfileBtnComponent } from './profile-btn/profile-btn.component';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  declarations: [
    EHButtonComponent,
    EhCustomInput,
    EhCustomPasswordInputComponent,
    SocialSignInComponent,
    ChatCardComponent,
    SimpleSliderComponent,
    ContentCardComponent,
    CourseCardComponent,
    ContinueAudioCardComponent,
    SimpleSquareCardComponent,
    CardWithProgressComponent,
    ProfileBtnComponent,
  ],
  imports: [CommonModule, FormsModule, IonicModule, ReactiveFormsModule],
  exports: [
    EHButtonComponent,
    EhCustomInput,
    EhCustomPasswordInputComponent,
    SocialSignInComponent,
    ChatCardComponent,
    SimpleSliderComponent,
    ContentCardComponent,
    CourseCardComponent,
    ContinueAudioCardComponent,
    SimpleSquareCardComponent,
    CardWithProgressComponent,
    ProfileBtnComponent,

  ],
})
export class SharedComponentsModule {}
