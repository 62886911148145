import {AuthenticationService} from '../services/authentication.service';
import {Injectable} from '@angular/core';
import {CanLoad, Router} from '@angular/router';
import {from, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {
  }

  canLoad(): Observable<boolean> {
    // console.log('AuthGuard',this.router.url);
    const token = this.authService.loadToken();
    if (token === null || token === undefined) {
      // console.log('AuthGuard',this.router.url, "to Main");
      this.router.navigateByUrl('/sign-up');
      return of(false);
    } else {
      // console.log('AuthGuard',this.router.url, token);
      return of(true);
    }
  }
}
