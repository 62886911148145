import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-continue-audio-card',
  templateUrl: './continue-audio-card.component.html',
  styleUrls: ['./continue-audio-card.component.scss'],
})
export class ContinueAudioCardComponent implements OnInit {

  @Input()progress=50;
  @Input()course='';
  @Input()content='';
  @Input()time='';
  @Input()imageURL='';

  constructor() { }

  ngOnInit() {}

}
