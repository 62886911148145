

<ion-content class="centered-content" [fullscreen]="true"  scroll-y="false">
  
  <div class="center-item">
  <form class="ion-padding "  [formGroup]="parentForm" (keydown.enter)="onSubmit()" (ngSubmit)="onSubmit()">
    <ion-text color="black">
      <h1 class="ion-padding title">
        Crea una nueva 
        contraseña
      </h1>
    </ion-text>

    <div class="input-space">
      <app-eh-custom-password-input
      (isValid)="setValidity('passwordAndConfirm', $event)"
      [isPasswordConfirm]="true"
      [(passwordValue)]="passwordValue"
      [(confirmPasswordValue)]="resetpasswordValue"
      >
        
      </app-eh-custom-password-input>
    </div>

    



    <div style=" justify-content: center;">
      <app-eh-button
        (btnClick)="onSubmit()"
        [expand]="true"
        text="Añadir nueva contraseña"
        [disable]="!isFormValid()"
        color="primary"
      ></app-eh-button>
    </div>
  </form>
</div>


</ion-content>

<ion-footer *ngIf="isKeyboardHide" class="ion-no-border">
  <ion-toolbar color="white">
    <ion-text  style="font-weight: 600;" > Ya tienes una cuenta? </ion-text>
    <ion-text  (click)="toLogin()" style="font-weight: 600; cursor: pointer;" color="primary">Accede</ion-text>
    
  </ion-toolbar>
</ion-footer>