<ion-tabs>

  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="courses">
      <ion-icon name="home"></ion-icon>
      <ion-label>Inicio</ion-label>
    </ion-tab-button>

    <ion-tab-button (click)="play()">
      <div class="player">
        <ion-icon name="code"></ion-icon>
      </div>
    </ion-tab-button>

    <ion-tab-button tab="profile">
      <ion-icon name="person"></ion-icon>
      <ion-label>Perfil</ion-label>
    </ion-tab-button>
  </ion-tab-bar>

</ion-tabs>
