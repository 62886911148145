<div class="form-input" [formGroup]="inputFormGroup">
  <!-- Show icon if iconName is defined -->
  <span *ngIf="iconName" class="icon"><ion-icon [name]="iconName"></ion-icon></span>

  <input
    [class.with-icon]="iconName"
    *ngIf="mode!=='message'"
    (focus)="focused=true"
    (blur)="focused=false"
    type={{getType()}}
    [class.borderFilled]="inputFormGroup.get('input')!.valid && inputFormGroup.get('input')!.touched && !focused"
    [class.borderInValid]="inputFormGroup.get('input')!.invalid && inputFormGroup.get('input')!.touched"
    [class.borderValid]="inputFormGroup.get('input')!.valid || !inputFormGroup.get('input')!.touched"
    formControlName="input"
    placeholder=" "
  />
  <textarea
  [class.with-icon]="iconName"
  *ngIf="mode==='message'"
  (focus)="focused=true"
  (blur)="focused=false"
  [class.borderFilled]="inputFormGroup.get('input')!.valid && inputFormGroup.get('input')!.touched && !focused"
  [class.borderInValid]="inputFormGroup.get('input')!.invalid && inputFormGroup.get('input')!.touched"
  [class.borderValid]="inputFormGroup.get('input')!.valid || !inputFormGroup.get('input')!.touched"
  formControlName="input"
  placeholder=" "
  rows="7"
></textarea>

  <label [class.errorLabel]="inputFormGroup.get('input')!.invalid && inputFormGroup.get('input')!.touched" for="email-input">
    {{getLabel() | titlecase}}
  </label>


  <span class="helper-text ion-text-wrap" 
  *ngIf="hasText&&( inputFormGroup.get('input')!.valid || !inputFormGroup.get('input')!.touched)">
  {{getHelperText()}}
  </span>

  <span  class="helper-text ion-text-wrap" 
  *ngIf="mode==='message'">
  {{inputFormGroup.get('input')!.value.length}}/ {{messageCharLimit}}
  </span>

  <span class="error-text"
  *ngIf="hasError&& !inputFormGroup.get('input')!.valid && inputFormGroup.get('input')!.touched">
  {{getErrorText()}}
  </span>
</div>
