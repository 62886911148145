import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { Subscription } from '../model/subscription.model';
import { RevolutService } from './payment/revolut.service';
import {
  IAPProduct,
  InAppPurchase2,
} from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  public subscriptions: Subscription[] = null;
  order: any;

  constructor(
    private api: ApiService,
    private auth: AuthenticationService,
    private revolut: RevolutService,
    private platform: Platform,
    private store: InAppPurchase2,
  ) // private ga: GoogleAnalytics,
  {
    this.init();
    if (platform.is("ios")) {
      this.prepareInAppPurchases();
    }
  
  }

  init() {
    return this.api.loadSubscriptions().subscribe((res: any) => {
      this.subscriptions = res;
      // console.log('response =====>', res);
    });
    window.addEventListener('paymentSuccess',this.paymentSuccessEvent.bind(this));
  }

  subscribe(subscription: Subscription) {
    // console.log('id', subscription.id);

    if (
      this.subscriptions.find((x) => x.id === subscription.id) === undefined
    ) {
      throw new Error('Subscription not found');
    }

    if (subscription.promoPrice > 0) {
      this.revolut.newOrder(subscription.promoPrice);
    } else {
      this.revolut.newOrder(subscription.price);
    }
  }

  subscribeIOS(subscription: Subscription) {
    if (
      this.subscriptions.find((x) => x.id === subscription.id) === undefined
    ) {
      throw new Error('Subscription not found');
    }

    if (subscription.promoPrice > 0) {
      this.revolut.newOrderIOS(subscription.promoPrice).subscribe((res: any) => {
        this.order = res.order;
      });
    } else {
      this.revolut.newOrderIOS(subscription.price).subscribe((res: any) => {
        this.order = res.order;
      });
    }

    if (subscription.id === '62a1d99d755e00006e0034b7') {
      this.store.order('03');
      window.dispatchEvent(new Event('paymentSuccess'));
    } else {
      this.store.order('04');
      window.dispatchEvent(new Event('paymentSuccess'));
    }
  }

  private paymentSuccessEvent(event){
    this.api.orderUpdate(this.order.id).subscribe(
      res => {
        this.revolut.publicId = null;
        console.log('ORDER SAVED',res);
      }
    );
    // @todo: Reset user permissions.
  }

  private prepareInAppPurchases() {
    // After platform ready
    this.store.verbosity = this.store.DEBUG;
    this.store.register([
      {
        id: '04',
        type: this.store.PAID_SUBSCRIPTION,
        alias: 'Anual 2022',
      },
      {
        id: '03',
        type: this.store.PAID_SUBSCRIPTION,
        alias: 'Mensual 2022',
      },
    ]);

    // Register event handlers for the specific product
    this.store.when('Anual 2022').registered((product: IAPProduct) => {
      console.log('Registered: ' + JSON.stringify(product));
    });

    this.store.when('Mensual 2022').registered((product: IAPProduct) => {
      console.log('Registered: ' + JSON.stringify(product));
    });

    // Updated
    this.store.when('Anual 2022').updated((product: IAPProduct) => {
      console.log('Updated' + JSON.stringify(product));
    });

    this.store.when('Mensual 2022').updated((product: IAPProduct) => {
      console.log('Updated' + JSON.stringify(product));
    });

    // User closed the native purchase dialog
    this.store.when('Anual 2022').cancelled((product) => {
      console.error('Purchase was Cancelled');
    });

    this.store.when('Mensual 2022').cancelled((product) => {
      console.error('Purchase was Cancelled');
    });

    // Track all store errors
    this.store.error((err) => {
      console.error('Store Error ' + JSON.stringify(err));
    });

    // Run some code only when the store is ready to be used
    this.store.ready(() => {
      console.log('Store is ready');
      console.log('Products: ' + JSON.stringify(this.store.products));
      console.log(JSON.stringify(this.store.get('Anual 2022')));
      console.log(JSON.stringify(this.store.get('Mensual 2022')));
    });

    // Refresh the status of in-app products
    this.store.refresh();
  }
}
