import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {UserService} from '../../services/user.service';
import {RevolutService} from '../../services/payment/revolut.service';
import {SubscriptionService} from '../../services/subscription.service';
import {Subscription} from '../../model/subscription.model';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit {

  private subscriptionSelected: Subscription = null;

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    public subscriptionService: SubscriptionService,
    private platform: Platform,
    private alertController: AlertController,
    private location: Location,
  ) { }

  ngOnInit() {
    this.subscriptionService.init().add(() => {
      this.subscriptionSelected = this.subscriptionService.subscriptions[0];
    });
  }

  dismiss() {
    this.modalController.dismiss();
  }

  subscribe() {
    // if(this.platform.is('ios')){
    //   this.alertController.create({
    //     header: 'Subscription',
    //     message: 'You are on IOS, please use the Web App store to subscrib or Android device',
    //     buttons: [{
    //       text: 'OK',
    //       handler: () => {
    //         window.open('https://emotionhack.com');
    //         this.dismiss();
    //       }
    //     }]
    //   }).then(alert => alert.present());
    //   return;
    // }

    if(this.subscriptionSelected === null){
      console.log('Subscription not found');
      return;
    }
    // this.subscriptionService.subscribe(this.subscriptionSelected);
    if (this.platform.is('ios')) {
      this.subscriptionService.subscribeIOS(this.subscriptionSelected)
    } else {
      this.subscriptionService.subscribe(this.subscriptionSelected);
    }
  }

  selectType(id: string) {
    this.subscriptionSelected = this.subscriptionService.subscriptions.find(x => x.id === id);
    if(this.subscriptionSelected === null){
      console.log('Subscription not found');
      return;
    }
    document.querySelectorAll('.suscription').forEach(el => {el.classList.remove('purple');});
    document.querySelector('#subscription_'+this.subscriptionSelected.id).classList.add('purple');
  }
}
