<ion-header class="bgPurple">
  <ion-toolbar>
    <ion-title class="ion-text-center">
      <ion-img src="/assets/icon/LogoWhiteIcon.png" style="width: 100px;margin:0 auto;"></ion-img>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="bgPurple">
  <form (ngSubmit)="submit()" [formGroup]="recoverForm">
    <ion-grid>
      <ion-row color="primary" justify-content-center>
        <ion-col align-self-center>
          <div padding>
            <ion-item>
              <ion-input formControlName="password" type="password" placeholder="Password"></ion-input>
              <span slot="error" *ngIf="!recoverForm.controls.password.valid" class="validator-error">
                6 caracteres, mayúsculas, minúsculas y números
              </span>
            </ion-item>

            <ion-item>
              <ion-input formControlName="passwordConfirmation" type="password" placeholder="Password again"></ion-input>
              <span slot="error" *ngIf="recoverForm.controls.password.valid && !recoverForm.controls.passwordConfirmation.valid" class="validator-error">
                Password mistmatch
              </span>
            </ion-item>
          </div>
          <div padding>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div class="footer">
      <ion-button class="white" size="large" type="submit" [disabled]="!recoverForm.valid" expand="block">Update password</ion-button>
      <ion-button expand="block" size="large" (click)="main()">Volver</ion-button>
    </div>

  </form>
</ion-content>
