import { Component, OnInit } from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {UserService} from '../../services/user.service';
import {AlertController, LoadingController} from '@ionic/angular';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private platform: Platform,
    private alertController: AlertController,
  ) { }

  ngOnInit() {

  }

  dismiss() {
    this.modalController.dismiss();
  }

  logout() {
    this.userService.logout();
    this.dismiss();
  }

 async subscriptionCancel() {
    const alert = await this.alertController.create({
      header : "Cancelar suscripción",
      message: 'Está seguro de que desea eliminar permanentemente su suscripción ?',
      buttons: [
        {
          text : "Cancelar"
        },
        {
          text : "Confirmar",
          handler : () => {
            this.userService.subscriptionCancel();
            this.dismiss();
          }
        }
    ],
    });
    await alert.present();
  
  }

  async deleteAccount() {
    const alert = await this.alertController.create({
      header : "Borrar cuenta",
      message: 'Está seguro de que desea eliminar la cuenta de forma permanente ?',
      buttons: [
        {
          text : "Cancelar"
        },
        {
          text : "confirmar",
          cssClass : ".my-custom-class",
          handler : () => {
            this.userService.deleteAccount();
            this.dismiss();
          }
        }
    ],
    });
    await alert.present();
  }

  isIos(){
    return this.platform.is('ios');
  }

  isSubscribed(){
    return this.userService.isSubscribed();
  }
}
