<ion-card *ngIf="hasArrow" class="ion-activatable flex-container">
    <ion-ripple-effect></ion-ripple-effect>
  <div class="flex-container">
    <ion-icon size="large" class="ion-padding" name="{{iconName}}-outline"></ion-icon>
    <h4>{{text}}</h4>

  </div>
  <ion-button fill="clear" color="dark" (click)="onClick()">
    <ion-icon slot="icon-only" size="large" class="ion-padding" name="chevron-forward-outline"></ion-icon>
  </ion-button>
</ion-card>

<ion-card *ngIf="!hasArrow" (click)="onClick()" class="ion-activatable"[class.red]="isRed" >
  <ion-ripple-effect></ion-ripple-effect>
  <div class="flex-center">
    <ion-icon size="large" class="ion-padding" name="{{iconName}}-outline"></ion-icon>
    <h4>{{text}}</h4>

  </div>
</ion-card>