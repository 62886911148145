import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-simple-square-card',
  templateUrl: './simple-square-card.component.html',
  styleUrls: ['./simple-square-card.component.scss'],
})
export class SimpleSquareCardComponent implements OnInit {
  @Input() courseID:string;
  @Input() imgURL!: string;
  @Input() text!: string;
  @Input() loading=false;

  constructor(  private router: Router) { }

  ngOnInit() {}

  showCourse(id: string) {
    this.router.navigateByUrl('/eh-course/' + id);
  }


}
