import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ContentNotAvailableComponent } from './content-not-available.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [ContentNotAvailableComponent],
  entryComponents: [ContentNotAvailableComponent],
  exports: [ContentNotAvailableComponent]
})
export class ContentNotAvailableModule { }
