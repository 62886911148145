import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-profile-btn',
  templateUrl: './profile-btn.component.html',
  styleUrls: ['./profile-btn.component.scss'],
})
export class ProfileBtnComponent implements OnInit {
  @Output() onclickCard: EventEmitter<void> =new EventEmitter<void>();
  @Input() iconName: string;
  @Input() text: string;
  @Input() isRed: boolean;
  @Input() hasArrow: boolean;

  constructor() { }

  ngOnInit() {}

  onClick() {
    this.onclickCard.emit();
  }
}
