<ion-header mode="md">
  <ion-toolbar
    class="header-with-image"
    [style.background]="backgroundImage"
    mode="md"
  >
    <ion-buttons slot="start" class="custom-buttons">
      <ion-button class="custom-button" (click)="back()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" class="custom-buttons">
      <ion-button class="custom-button" (click)="shareCourse()">
        <ion-icon name="share-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div>
    <div class="content">
      <div class="title">{{course.name}}</div>
      <div class="subtitle">
        <ion-icon name="volume-medium" class=" "></ion-icon>
        <span class="text">
          {{numberAudios}} audios - {{durationAudios}} min</span
        >
      </div>
      <span class="text2">{{ course.description }}.</span>
    </div>

    <!-- <br /> -->
    <!-- <div>
      <ion-chip [outline]="true">Resiliencia</ion-chip>
      <ion-chip [outline]="true">Liderazgo</ion-chip>
      <ion-chip [outline]="true">Miedo</ion-chip>
      <ion-chip [outline]="true">Inseguridad</ion-chip>
    </div> -->

    <hr />
    <div class="title">Contenido:</div>

    <ng-container *ngFor="let content of course.contents">
      <div *ngIf="(content.parent ===null)">
        <app-card-with-progress
          [continue]="false"
          (click)="loadInPlayer(content)"
          [locked]="isLocked(content)"
          [content]="content.name"
          [time]="content.totalMinutes() + 'min'"
          [progress]="getProgress(course,content)"
          [selected]="isListened(content)"
        ></app-card-with-progress>
      </div>
    </ng-container>
    <div>
      <!-- <app-card-with-progress
    *ngFor="let item of list"
    [continue]="false"
    [locked]="false"
    content={{item.title}}
    time={{item.duration}}
    [progress]="0"

    ></app-card-with-progress> -->
    </div>
  </div>
</ion-content>
<ion-footer>
  <app-eh-button
    [expand]="true"
    iconSlot="start"
    (btnClick)="startedCourse ? startCourse() : continueCourse()"
    iconName="play"
    [text]="!startedCourse? 'Iniciar curso':'Continuar curso'"
  >
  </app-eh-button>
</ion-footer>
