<div *ngIf="!loading" class="simple-card  ion-activatable" (click)="showCourse(courseID)">
  <ion-ripple-effect></ion-ripple-effect>
  <div>
  <ion-img src={{imgURL}} class="full-width-image"></ion-img>
</div>

  <span class="text">{{text}}</span>

</div>

<div *ngIf="loading" class=" simple-card text-center">
  <!-- <ion-img src="../../../assets\Frame 7331.svg" class="full-width-image"></ion-img> -->
  <div class="full-width-image-loading">
    <div class="static-content"></div>
  </div>
  <ion-skeleton-text [animated]="true" style="width: 80%; height: 20px;"></ion-skeleton-text> 


</div>