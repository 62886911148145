import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import RevolutCheckout from '@revolut/checkout';
import {LoadingController} from '@ionic/angular';
import {UserService} from '../user.service';
import {ApiService} from '../api.service';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import RevolutCheckoutLoader from '@revolut/checkout';
import mode = RevolutCheckoutLoader.mode;

@Injectable({
  providedIn: 'root'
})
export class RevolutService {
  mode = environment.revolut.mode;

  order: any;

  card: any;

  publicId: string;
  private instance2;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private apiService: ApiService,
    private router: Router,
    private loadingController: LoadingController,

  ) {
    window.addEventListener('paymentSuccess',this.paymentSuccessEvent.bind(this));
  }


  newOrder(amount: number) {
    // if(this.publicId !== undefined){
    //   return this.openModalPayment();
    // }
    this.loadingController.create().then(loading => {
      loading.present();
      this.apiService.orderCreate(amount).toPromise().then(
        res => {
          this.order = res.order;
          this.publicId = this.order.revolut.publicId;
          // console.log('ORDER',this.order);
          loading.dismiss();
          this.openModalPayment();
        }
      );
    });
  }

  newOrderIOS(amount: number) {
    // if(this.publicId !== undefined){
    //   return this.openModalPayment();
    // }
      return this.apiService.orderCreate(amount);
  }

  private openModalPayment(){
    if(this.publicId === undefined){
      console.error("Payment error: publicId is undefined");
      return false;
    }

    if(environment.revolut.mode == 'live'){
      RevolutCheckout(this.publicId).then( instance => this.openModalPaymentInstance(instance));
    }else if(environment.revolut.mode == 'sandbox'){
      RevolutCheckout(this.publicId,'sandbox').then( instance => this.openModalPaymentInstance(instance));
    }
  }

  private openModalPaymentInstance(instance){
    this.instance2 = instance.payWithPopup({
      name: this.userService.getName(),
      email: this.userService.getEmail(),
      savePaymentMethodFor: 'merchant',
      onSuccess() {
        // saveOrder();
        window.dispatchEvent(new Event('paymentSuccess'));
        // window.alert('Thank you!');
      },
      // Callback in case some error happened
      onError(message) {
        console.log('Revolut error: ', message);
        // window.alert('Oh no :(');
      },
      // (optional) Callback in case user cancelled a transaction
      onCancel() {
        window.alert('Payment cancelled!');
      },
    });
  }

 private paymentSuccessEvent(event){
    this.apiService.orderUpdate(this.order.id).subscribe(
      res => {
        this.publicId = null;
        // console.log('ORDER SAVED',res);
      }
    );
    // @todo: Reset user permissions.
  }
}
