import { ToastController } from '@ionic/angular';
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, NgZone } from '@angular/core';
import { UserService } from './services/user.service';
import { PlayerService } from './services/player.service';
import { ApiService } from './services/api.service';
import { StateService } from './services/state.service';
import { AuthenticationService } from './services/authentication.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  NavController,
  Platform,
} from '@ionic/angular';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { ValidateEmailComponent } from './components/validate-email/validate-email.component';
import { CoursePageModule } from './pages/course/course.module';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { NewVersionModalComponent } from './modal/new-version-modal/new-version-modal.component';
import { ResetPasswordPageModule } from './pages/reset-password/reset-password.module';
import { EhCoursePageModule } from './pages/eh-course/eh-course.module';
import { App, URLOpenListenerEvent } from '@capacitor/app';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  // isInit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading: HTMLIonLoadingElement = null;
  newVersionAvailable = false;
  constructor(
    private api: ApiService,
    private userService: UserService,
    private player: PlayerService,
    private stateService: StateService,
    private auth: AuthenticationService,
    private router: Router,
    private loadingController: LoadingController,
    private platform: Platform,
    protected navController: NavController,
    protected deeplinks: Deeplinks,
    private alertCtrl: AlertController,
    private apiService: ApiService,
    private modalController: ModalController,
    private zone: NgZone,
    private toastCtrl: ToastController,
  ) {
    this.initializeApp();
    this.platform.backButton.subscribeWithPriority(10, () => {
      const currentUrl = this.router.url;
      // console.log(currentUrl, 'hello');
      if (currentUrl === '/home' || currentUrl === '/sign-up') {
        const confirmar = this.alertCtrl
        .create({
          header: 'Salir',
          message: '¿Deseas salir?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
            },
            {
              text: 'Sí',
              handler: () => {
                navigator['app'].exitApp();
              },
            },
          ],
        })
        .then((alerta) => {
          alerta.present();
        });

      } else {
        this.navController.back();
      }
    });
//TODO fix in cap

    this.platform.ready().then(() => {
      this.deeplinks
        .route({
          '/eh-course/:courseId': EhCoursePageModule,
          '/validateEmail/:userId/:userEmail': ValidateEmailComponent,
          '/reset-password/:token': ResetPasswordPageModule,
        })
        .subscribe(
          (match) => {
            // match.$route - the route we matched, which is the matched entry from the arguments to route()
            // match.$args - the args passed in the link
            // match.$link - the full link data
            console.log('Successfully matched route', match);
            //TODO change to new pages
            if (match.$route.name === 'EhCoursePageModule') {
              this.navController.navigateForward([
                '/eh-course',
                match.$args.courseId,
              ]);
            } else if (match.$route.name === 'ValidateEmailComponent') {
              this.navController.navigateForward([
                '/validateEmail',
                match.$args.userId,
                match.$args.userEmail,
              ]);
            } else if (match.$route.name === 'ResetPasswordPageModule') {
              this.navController.navigateForward([
                '/reset-password',
                match.$args.token,
              ]);
            }
          },
          (nomatch) => {
            // nomatch.$link - the full link data
            console.error("Got a deeplink that didn't match", nomatch);
          }
        );

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // this.firebase.setScreenName(event.urlAfterRedirects);
        }
      });

      // console.log('Version deployed AMZ');
    });

    this.checkLatestVersion();
    this.loadingController.create().then((loading) => {
      this.loading = loading;
      const token = this.auth.loadToken();
      if (!token) {
        return;
      }
      this.loadBaseResources().add(() => {
        // console.log("LOad ends");
        this.loading.dismiss();
      });
    });
  }
  checkLatestVersion() {
    this.apiService.getVersion().subscribe((response) => {
      const latestVersion = response.version;

      // console.log(
      //   `Current version: ${environment.version}, Latest Version: ${latestVersion}`
      // );
      if (latestVersion !== environment.version) {
        console.log('A new version is available!');
        this.newVersionAvailable = true;
      }
      this.presentModalIfNeeded();
    });
  }
  initializeApp() {

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(async () => {
          // Define base URLs for production and testing
          const baseUrlProd = 'app.emotionhack.com';
          const baseUrlTest = 'app-test.emotionhack.com';

          // Check if URL starts with the defined base URLs
          if (event.url.includes(baseUrlProd) || event.url.includes(baseUrlTest)) {
              // Determine the appropriate base URL for splitting
              const baseUrl = event.url.includes(baseUrlProd) ? baseUrlProd : baseUrlTest;

              // Split URL to get the slug after the base URL
              const slug = event.url.split(baseUrl).pop();

              if (slug) {
                  // Navigate to the extracted slug within the app
                  this.router.navigateByUrl(slug);
              }
          }
          // If URL does not match the base URLs, regular routing logic will take over
      });
  });
}
  async openNewVersionModal() {
    const modal = await this.modalController.create({
      component: NewVersionModalComponent,
      componentProps: {
        // You can pass additional data to the modal component if needed
      },
    });
    return await modal.present();
  }
  async presentModalIfNeeded() {
    if (this.newVersionAvailable) {
      await this.openNewVersionModal();
    }
  }
  loadBaseResources() {
    return this.api.loadCourses().subscribe((courses) => {
      this.api.courses = courses;
      // console.log('courses', courses);
      this.userService.getUser('').subscribe((user) => {
        const state = this.userService.getLastState();
        if (state !== undefined) {
          // console.log('reload state', state);
          this.stateService.loadState(state);
          this.player.initContent();
        }
        // console.log('user', this.userService.getUser());
        // console.log('state', this.stateService.state);
        // this.loading.dismiss();
        // this.isInit.next(true);
      });
    });
  }
}
