import { tap } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnInit {
  @Input() courseTitle='';
  @Input() courseDescription='';
  @Input() time='';
  @Input() imageURL='';
  @Input() loading=false;

  constructor() { }
  ngOnInit() {}
}
