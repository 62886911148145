import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Content } from 'src/app/model/content';
import { Course } from 'src/app/model/course';
import { StateCourse } from 'src/app/model/state.model';
import { ApiService } from 'src/app/services/api.service';
import { PlayerService } from 'src/app/services/player.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';
import { Share } from '@capacitor/share';
import { ContentNotAvailableComponent } from 'src/app/modal/content_not_available/content-not-available.component';
import { StateContent } from 'src/app/model/stateContent.model';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-eh-course',
  templateUrl: './eh-course.page.html',
  styleUrls: ['./eh-course.page.scss'],
})
export class EhCoursePage implements OnInit {
  //TODO remove
  // list=[
  //   {title: 'Comunicar, mucho más que hablar', duration: ' 04:03 min'},
  //   {title: 'Hablando contigo mismo', duration: '04:03 min'},
  //   {title: 'Comunicar para construir', duration: '04:03 min'},
  //   {title: 'Mitos sobre la comunicación', duration: '04:03 min'},
  //   {title: 'Comunicar, mucho más que hablar', duration: '04:03 min'},
  //   {title: 'Hablando contigo mismo', duration: '04:03 min'},
  //   {title: 'Comunicar para construir', duration: '04:03 min'},
  //   {title: 'Mitos sobre la comunicación', duration: '04:03 min'}
  // ];

  course: Course;
  // contents: Content[];
  numberAudios = 0;
  durationAudios = 0;
  startedCourse = false;
  courseState: StateCourse;
  courseId: any = '';
  backgroundImage = `transparent url('../../../assets/Toma de decisiones.svg') center/cover no-repeat`;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public modalController: ModalController,
    public player: PlayerService,
    // private ga: GoogleAnalytics,
    private userService: UserService,
    private stateService: StateService
  ) {
    this.course = new Course();
  }

  ngOnInit() {
    this.courseId = this.route.snapshot.paramMap.get('courseId');

    this.apiService
      .getCourse(this.route.snapshot.paramMap.get('courseId'))
      .then((course) => {
        if (course == null) {
          this.router.navigate(['/courses']);
        }
        this.course = course;
        if (course.backgroundImage) {
          this.backgroundImage = `transparent url('${course.backgroundImage}') center/cover no-repeat`;
        } else {
          this.backgroundImage = `transparent url('${course.icon}') center/cover no-repeat`;
        }

        this.userService.getStateCourse(this.course).then((stateCourse) => {
          this.courseState = stateCourse;
          if (this.courseState) {
            this.startedCourse = true;
            this.courseState.contentsState?.forEach((e) => {
              this.course.progressDict[e.contentId] = e.seconds;
              this.course.contents = this.course.contents.map((content) => {
                const newContent = content;
                if (newContent.id === e.contentId) {
                  newContent.lastProgress = e.seconds;
                }
                return newContent;
              });
            });
          }

          this.course.contents.forEach((content) => {
            if (content.type === 'audio') {
              if (content.parent == null) {
                this.numberAudios++;
              }
              this.durationAudios += content.duration;

              content.totalTime =
                content.duration +
                content.calculateTotalTime(this.course.contents, content.id);
            }
          });
          this.durationAudios = Math.round(this.durationAudios / 60);

          // console.log(this.course.progressDict);
        });
      });
  }

  back() {
    this.router.navigateByUrl('/home');
  }
  isLocked(content: Content) {
    if (content.isFree() || this.userService.isSubscribed()) {
      return false;
    } else {
      return true;
    }
  }
  isListened(content: Content) {
    if (this.courseState == null) {
      return false;
    }
    return this.courseState.isListenedContent(content);
  }
  getProgress(course: Course, content: Content) {
    const thisProgress = course.progressDict[content.id];
    let childProgress = content.calculateTotalProgress(course, content.id);
    if (isNaN(childProgress)) {
      childProgress = 0;
    }
    const progress = Math.round(
      ((thisProgress + childProgress) * 100) / content.totalTime
    );
    return progress;
  }
  async shareCourse() {
    const { value } = await Share.canShare();
    if (value) {
      await Share.share({
        title: 'EmotionHack',
        text: 'Estoy mejorando mi ' + this.course.name + ', ¿te apuntas?',
        url: 'https://app.emotionhack.com/eh-course/' + this.course.id,
        dialogTitle: 'Compartir con amigos',
      });
    }
  }
  async loadInPlayer(content: Content) {
    if (this.isLocked(content)) {
      this.modalNoContent();
      return;
    }
    this.startedCourse = true;
    this.player.loadContent(this.course, content).then(() => {
      // this.ga.trackEvent('Course', 'Play', content.name);
      this.router.navigateByUrl('/player');
    });
  }
  startCourse() {
    this.startedCourse = true;
    this.stateService.loadState(this.courseState);
    if (!this.startedCourse) {
      this.loadInPlayer(this.course.contents[0]);
    } else {
      if (!this.courseState) {
        this.loadInPlayer(this.course.contents[0]);
      } else {
        this.loadInPlayer(this.courseState.content);
      }
    }
  }
  modalNoContent() {
    this.modalController
      .create({
        // component: SubscribeComponent,
        component: ContentNotAvailableComponent,
        // componentProps: {
        //   course: this.course,
        // },
      })
      .then((modal) => {
        modal.present();
      });
  }
  async continueCourse() {
    this.stateService.loadState(this.courseState);
    this.stateService.loadContentLast();
    this.player
      .loadContent(
        this.stateService.getCourse(),
        this.stateService.getContentLast()
      )
      .then(() => {
        this.router.navigateByUrl('/player');
      });
  }
}
