<div class="card" [class.selected]="selected" [class.not-selected]="!selected">
  <div class="content">
      <div class="title">{{title}}</div>
      <div class="subtitle">
          <ion-icon name="volume-high"></ion-icon>
          <span class="duration">{{time}}</span>
      </div>
  </div>
  <!-- Ionicon inside a rounded button -->
  <button *ngIf="locked" class="icon-button-lock">
    <ion-icon name="lock-closed"></ion-icon>
  </button>
  <button  *ngIf="!locked"  [class.icon-button-selected]="selected" [class.icon-button-not-selected]="!selected">
    <ion-icon   name="play"></ion-icon>
</button>
  <div *ngIf="progress!==0" class="progress-bar-empty"></div>
  <div *ngIf="progress!==0" class="progress-bar-full" [style.width]="computedWidth"></div>
</div>
