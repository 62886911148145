import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, AlertController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordPage implements OnInit {
  parentForm!: FormGroup;
  isKeyboardHide=true;
  passwordValue = '';
  resetpasswordValue = '';
  validities: { [key: string]: boolean } = {
    passwordAndConfirm: false,
  };

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private loadingController: LoadingController,
    private authService: AuthenticationService,
    private alertController: AlertController,
    private keyboard: Keyboard,
  ) {}

  ngOnInit(): void {

    this.parentForm = this.fb.group({});
  }
  ionViewWillEnter() {
    this.keyboard.onKeyboardWillShow().subscribe(()=>{
      this.isKeyboardHide=false;

    });

    this.keyboard.onKeyboardWillHide().subscribe(()=>{
      this.isKeyboardHide=true;

    });
  }
  setValidity(type: string, isValid: boolean | any) {
    this.validities[type] = isValid;
  }

  toLogin()
  {
    this.router.navigate(['/log-in']);
  }
  isFormValid(): boolean {
    return (
      this.passwordValue !== null &&
      this.passwordValue === this.resetpasswordValue &&
      Object.values(this.validities).every((v) => v)
    );
  }
  onSubmit(): void {
    // console.log('Form Submitted!', {
    //   password: this.passwordValue,
    //   resetpassword: this.passwordValue,
    // });
    if(!this.isFormValid()){
      return;
    }
    
    this.submit();
  }
  async submit() {
    const loading = await this.loadingController.create();
    await loading.present();
    // console.log(this.passwordValue, this.route.snapshot.paramMap.get('token'));
    const data = {
      password: this.passwordValue,
      passwordConfirmation: this.resetpasswordValue,
    };
    this.authService
      .recoverPasswordToken(
        this.route.snapshot.paramMap.get('token'),
       data
      )
      .subscribe(
        async () => {
          await loading.dismiss();
          const alert = await this.alertController.create({
            header: 'Contraseña Cambiada Exitosamente',
            message: 'Tu contraseña ha sido cambiada exitosamente. Ahora puedes iniciar sesión con tu nueva contraseña.',
            buttons: [
              {
                text: 'OK',
                handler: () => {
                  this.router.navigate(['/log-in']); // Navigate to the login page
                },
              },
            ],
          });


          await alert.present();
        },
        async (error) => {
          loading.dismiss();
          const alert = await this.alertController.create({
            header: 'Error',
            message: error.error.message,
            buttons: ['OK'],
          });
          await alert.present();
        }
      );
  }
}
