import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertController} from '@ionic/angular';

@Component({
  selector: 'app-validate-email',
  template: ``
})
export class ValidateEmailComponent implements OnInit {

  @Input() userId: string;
  @Input() userEmail: string;
  constructor(
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private alertController: AlertController,
  ) {
  }

  ngOnInit() {
    this.auth.validateEmail(this.route.snapshot.paramMap.get('userEmail'), this.route.snapshot.paramMap.get('userId')).subscribe(
      async (data) => {
        // console.log(data);
        if(data.message === 'Email valid') {
          const alert = await this.alertController.create({
            header: 'Success',
            message: 'Tu email ha sido validado',
            buttons: [
              {
                text: 'OK',
                handler: () => {
                  this.router.navigate(['/intro']);
                }
              }
            ]
          });
          await alert.present();
        } else {
          const alert = await this.alertController.create({
            header: 'Error',
            message: 'Ha habido un error validando tu email',
            buttons: [
              {
                text: 'OK',
                handler: () => {
                  this.router.navigate(['/login']);
                }
              }
            ]
          });
          await alert.present();
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

}
