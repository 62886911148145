import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroGuard } from './guards/intro.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { ValidateEmailComponent } from './components/validate-email/validate-email.component';
import { RecoverComponent } from './components/recover/recover.component';
import { ExternalLoginGuard } from './guards/external-login.guard';

const routes: Routes = [
  {
    path: 'test-new-components',
    loadChildren: () => import('./test-new-components/test-new-components.module').then( m => m.TestNewComponentsPageModule)
  },

  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canLoad: [AutoLoginGuard], // Check if we should show the introduction or forward to inside
    canActivate: [AutoLoginGuard],
  },
  {
    path: 'log-in',
    loadChildren: () => import('./pages/log-in/log-in.module').then( m => m.LogInPageModule),
    canLoad: [AutoLoginGuard], // Check if we should show the introduction or forward to inside
    canActivate: [AutoLoginGuard],
  },
  {
    path: 'gen-exit-login',
    loadChildren: () => import('./pages/gen-exit-login/gen-exit-login.module').then( m => m.GenExitLoginPageModule),
    canLoad: [AutoLoginGuard], // Check if we should show the introduction or forward to inside
    canActivate: [AutoLoginGuard],
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./pages/forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule),
    canLoad: [AutoLoginGuard],
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./verify-email/verify-email.module').then( m => m.VerifyEmailPageModule),
    canLoad: [AutoLoginGuard],
  },
  {
    path: 'validateEmail/:userId/:userEmail',
    component: ValidateEmailComponent,
  },
  {
    path: 'recover/:token',
    component: RecoverComponent,
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'delete-profile/:token',
    loadChildren: () => import('./pages/delete-profile/delete-profile.module').then( m => m.DeleteProfilePageModule)
  },

  {
    path: 'intro',
    loadChildren: () =>
      import('./pages/intro/intro.module').then((m) => m.IntroPageModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
    canLoad: [AutoLoginGuard],
  },
    {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then( m => m.SignUpPageModule)
    ,    canLoad: [AutoLoginGuard],
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./pages/main/main.module').then((m) => m.MainPageModule),
    canLoad: [AutoLoginGuard], // Check if we should show the introduction or forward to inside
  },
  {
    path: 'course/:courseId/:jwtToken',
    loadChildren: () =>
      import('./pages/course/course.module').then((m) => m.CoursePageModule),
    canLoad: [ExternalLoginGuard, AuthGuard],
  },
  {
    path: 'course/:courseId',
    loadChildren: () =>
      import('./pages/course/course.module').then((m) => m.CoursePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'eh-course/:courseId',
    loadChildren: () => import('./pages/eh-course/eh-course.module').then( m => m.EhCoursePageModule),
    canLoad: [AuthGuard],
  },

  {
    path: 'player',
    loadChildren: () =>
      import('./pages/player/player.module').then((m) => m.PlayerPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'end/:courseId',
    loadChildren: () =>
      import('./pages/end-audio/end-audio.module').then(
        (m) => m.IntroPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
    // import('./tabs/tabs.module').then((m) => m.TabsPageModule),

      import('./new-tabs/new-tabs.module').then((m) => m.NewTabsPageModule),
    canLoad: [AuthGuard], // Secure all child pages
  },
  {
    path: '',
    redirectTo: '/sign-up',
    pathMatch: 'full',
  },


  {
    path: 'new-tabs',
    loadChildren: () => import('./new-tabs/new-tabs.module').then( m => m.NewTabsPageModule)
  },

  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'payment-policy',
    loadChildren: () => import('./pages/payment-policy/payment-policy.module').then( m => m.PaymentPolicyPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'rafa',
    loadChildren: () => import('./pages/rafa/rafa.module').then( m => m.RafaPageModule)
  },












];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
