import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';

// import { Storage } from '@capacitor/storage';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ApiResponse } from '../model/apiResponse';

const ACCESS_TOKEN_KEY = 'my-access-token';
const REFRESH_TOKEN_KEY = 'my-refresh-token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentAccessToken = null;
  url = environment.apiUrl;

  constructor(private http: HttpClient, private router: Router) {
    this.loadToken();
  }

  getIsAuthenticated(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  getIsLoaded(): Observable<boolean> {
    return this.isLoaded.asObservable();
  }

  loadToken(): string | null {
    if (this.isLoaded.getValue()) {
      // console.log('Get token from memory');
      return this.currentAccessToken;
    }
    const accessToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);
    if (accessToken) {
      this.currentAccessToken =
        accessToken === 'undefined' || accessToken === 'null'
          ? null
          : accessToken;
      this.isAuthenticated.next(true);
      this.isLoaded.next(true);
      return this.currentAccessToken;
    }
    this.isAuthenticated.next(false);
    this.isLoaded.next(false);
    return null;

    // Storage.get({key: ACCESS_TOKEN_KEY}).then(({value}) => {
    //   if (value) {
    //     this.currentAccessToken = value;
    //     this.isAuthenticated.next(true);
    //     this.isLoaded.next(true);
    //     return value;
    //   }
    // });

    // from(Storage.get({ key: ACCESS_TOKEN_KEY })).pipe(
    //   tap(token => {
    //     // debugger;
    //     if (token && token.value) {
    //       this.currentAccessToken = token.value;
    //       this.isAuthenticated.next(true);
    //       this.isLoaded.next(true);
    //       return token.value;
    //     }
    //     const accessToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);
    //     if(accessToken){
    //       this.currentAccessToken = accessToken;
    //       this.isAuthenticated.next(true);
    //       this.isLoaded.next(true);
    //       return accessToken;
    //     }
    //     this.isAuthenticated.next(false);
    //     this.isLoaded.next(false);
    //     return null;
    //   }),
    //   map(token => token.value)
    // );
  }

  login(credentials: { username; password }): Observable<any> {
    //console.log("Login",`${this.url}/auth/login_check`,credentials);
    return this.http.post(`${this.url}/auth/login_check`, credentials).pipe(
      // map((data: any) => data.token),
      switchMap((tokens: { token; refreshToken }) =>
        this.storeAccessToken(tokens.token, tokens.refreshToken)
      ),
      tap((_) => {
        //@todo: Reload all data with authenticated user.
        this.router.navigateByUrl('/');
      })
    );
  }

  exchangeCodeForTokens(code: string): Observable<any> {
    return this.http.post(`${this.url}/genexit/exchange-and-fetch/${code}`, {}).pipe(
      switchMap((tokens: { token; refreshToken }) =>
        this.storeAccessToken(tokens.token, tokens.refreshToken)
      ),
      tap((_) => {
        //@todo: Reload all data with authenticated user.
        this.router.navigateByUrl('/');
      })
    );
  }
  generateTokens(credentials: { username; password }) {
    return this.http.post(`${this.url}/auth/login_check`, credentials);
  }

  // Potentially perform a logout operation inside your API
  // or simply remove all local tokens and navigate to login
  logout() {
    return this.http
      .get(`${this.url}/auth/logout`)
      .pipe(
        switchMap((_) => {
          this.cleanTokens();
          return of(true);
        }),
        tap((_) => {
          this.isAuthenticated.next(false);
          this.router.navigateByUrl('/sign-up', { replaceUrl: true });
          window.location.reload();
        })
      )
      .subscribe();
  }

  // Load the refresh token from storage
  // then attach it as the header for one specific API call
  getNewAccessToken() {
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);
    if (!refreshToken) {
      return of(null);
    }
    return this.http.post(`${this.url}/auth/refresh`, { refreshToken });
    // const refreshToken = from(Storage.get({ key: REFRESH_TOKEN_KEY }));
    // return refreshToken.pipe(
    //   switchMap(token => {
    //     if (token && token.value && token.value !== 'undefined') {
    //       // console.log('refresh',token);
    //       return this.http.post(`${this.url}/auth/refresh`, {refreshToken:token.value});
    //     } else {
    //       // No stored refresh token
    //       this.logout();
    //       return of(null);
    //     }
    //   })
    // );
  }

  // Store a new access token
  storeAccessToken(accessToken, refreshToken) {
    console.log('accessToken', accessToken);

    this.currentAccessToken = accessToken;
    // window.localStorage.set({ key: ACCESS_TOKEN_KEY, value: accessToken });
    // window.localStorage.set({ key: REFRESH_TOKEN_KEY, value: refreshToken });
    window.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    this.isAuthenticated.next(true);
    return from(Promise.all([accessToken, refreshToken]));
  }

  cleanTokens() {
    this.currentAccessToken = null;
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    this.isAuthenticated.next(false);
  }

  register(data: { name; email; password }): Observable<any> {
    return this.http.post(`${this.url}/auth/register`, data);
  }

  //Request server to send a new email with a recover password token
  recoverPassword(email: string) {
    // console.log('recoverPassword', email);
    return this.http.post(`${this.url}/auth/recover`, { email: email });
  }

  validateEmail(userEmail: string, userId: string) {
    return this.http.get<ApiResponse>(
      `${this.url}/auth/validateEmail/${userId}/${userEmail}`
    );
  }

  recoverPasswordToken(token: string, passwords: any) {
    return this.http.post(`${this.url}/auth/recover/${token}`, passwords);
  }

  setToken(path: string) {
    return this.storeAccessToken(path, null);
  }
}
