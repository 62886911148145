import { Content } from './content';

export class Course {
  id: string;
  name: string;
  description: string;
  duration: number;
  updatedAt: any;
  status: '';
  contents: Content[];
  backgroundImage = '';
  icon = '';
  Fullylistened: any;
  // TODO : Get rid of progressDict
  progressDict: { [contentId: string]: number } = {};
  lastListen: Date;
  endedDate: Date;

  constructor(result: any = {}) {
    this.id = result.id;
    this.name = result.name;
    this.description = result.description;
    this.duration = result.duration;
    this.status = result.status;
    if (result.background) {
      this.backgroundImage = result.background;
    }
    if (result.icon) {
      this.icon = result.icon;
    }
    if (result.contents !== undefined) {
      this.contents = result.contents.map((x) => new Content(x));
      this.contents.sort((a, b) => a.order - b.order);
    }
  }

  /**
   * Get next content in course
   * If the content not exist in course or if it's the last element null returned.
   *
   * @param content
   */
  getNextContent(content: Content, allowQuestion?: boolean): Content {
    // console.log("contents in getNextContext ", this.contents);
    // console.log('content in getNextContext ', content);
    const index = this.contents.findIndex((item) => item.id === content.id);
    // console.log('index in getNextContext' , index);
    if (index < 0) {
      // console.log("in getNextContext <0 ");
      return null;
    }
    if (index === this.contents.length - 1) {
      // console.log("in getNextContext  - 1");
      return null;
    }
    if (!allowQuestion && this.contents[index + 1].type === 'question') {
      return this.getNextContent(this.contents[index + 1]);
    }
    // const value  = index.length -1
    return this.contents[index + 1];
  }
}
