
<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Indisponible</ion-title>
    <ion-buttons slot="start">
      <ion-button mode="md" (click)="dismissModal()"><ion-icon  mode="md" slot="icon-only" name="close"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="white">
  <div class="modal-container">
    <div class="modal-content">
      <h4 class="modal-message">Este contenido no está disponible!</h4>
    </div>
  </div>
</ion-content>
