<div  [formGroup]="inputFormGroup">
  <div class="form-input input-space">
    <!-- Show icon if iconName is defined -->
    <span *ngIf="iconName" class="icon"
      ><ion-icon [name]="iconName"></ion-icon
    ></span>

    <input
      [class.with-icon]="iconName"
      (focus)="focused = true"
      (blur)="focused = false"
      [type]="showPassword ? 'text' : 'password'"
      [class.borderFilled]="
        inputFormGroup.get('password')!.valid &&
        inputFormGroup.get('password')!.touched &&
        !focused
      "
      [class.borderInValid]="
        inputFormGroup.get('password')!.invalid &&
        inputFormGroup.get('password')!.touched
      "
      [class.borderValid]="
        inputFormGroup.get('password')!.valid ||
        !inputFormGroup.get('password')!.touched
      "
      formControlName="password"
      placeholder=" "
    />

    <label
      [class.errorLabel]="
        inputFormGroup.get('password')!.invalid &&
        inputFormGroup.get('password')!.touched
      "
      for="email-input"
    >
      {{ labelPassword | titlecase }}
    </label>

    <ion-icon
      slot="end"
      class="passIcon"
      name="{{ showPassword ? 'eye-off-outline' : 'eye-outline' }}"
      (click)="togglePassword()"
    >
    </ion-icon>

    <span
      class="helper-text ion-text-wrap"
      *ngIf="
        hasText &&
        (inputFormGroup.get('password')!.valid ||
          !inputFormGroup.get('password')!.touched)
      "
    >
      {{
        "Choose a strong password with a mix of letters, numbers, and symbols."
      }}
    </span>

    <span
      class="error-text"
      *ngIf="
      hasError&&
        !inputFormGroup.get('password')!.valid &&
        inputFormGroup.get('password')!.touched
      "
    >
      {{
        'Tu contraseña es muy débil. Por favor, incluye letras, números y símbolos "@#*^¨\'¡?"'
      }}
    </span>
  </div>

  <!-- Confirm Password -->
  <div *ngIf="isPasswordConfirm" class="form-input input-space">
    <!-- Show icon if iconName is defined -->
    <span *ngIf="iconName" class="icon"
      ><ion-icon [name]="iconName"></ion-icon
    ></span>

    <input
      [class.with-icon]="iconName"
      (focus)="focused = true"
      (blur)="focused = false"
      [type]="showConfirmPassword ? 'text' : 'password'"
      [class.borderFilled]="
        inputFormGroup.get('confirm')!.valid &&
        inputFormGroup.get('confirm')!.touched &&
        !focused
      "
      [class.borderInValid]="
        inputFormGroup.get('confirm')!.invalid &&
        inputFormGroup.get('confirm')!.touched
      "
      [class.borderValid]="
        inputFormGroup.get('confirm')!.valid ||
        !inputFormGroup.get('confirm')!.touched
      "
      formControlName="confirm"
      placeholder=" "
    />

    <label
      [class.errorLabel]="
        inputFormGroup.get('confirm')!.invalid &&
        inputFormGroup.get('confirm')!.touched
      "
      for="email-input"
    >
      {{ labelConfirm  }}
    </label>
    <ion-icon
      slot="end"
      class="passIcon"
      name="{{ showConfirmPassword ? 'eye-off-outline' : 'eye-outline' }}"
      (click)="toggleShowConfirmPassword()"
    >
    </ion-icon>

    <span
      class="helper-text ion-text-wrap"
      *ngIf="
        hasText &&
        (inputFormGroup.get('confirm')!.valid ||
          !inputFormGroup.get('confirm')!.touched)
      "
    >
      {{ "ReEnter the password" }}
    </span>

    <span
      class="error-text"
      *ngIf="
      hasError&&
        !inputFormGroup.get('confirm')!.valid &&
        inputFormGroup.get('confirm')!.touched
      "
    >
      {{ "Introduce de nuevo tu contraseña" }}
    </span>
  </div>
</div>
