import { Content } from './content';
import { Course } from './course';
import { Event } from './event.model';
import { StateContent } from './stateContent.model';

//State of user in course
export class StateCourse {
  dateStart: Date;
  dateEnd: Date = null;

  dateLastListen: Date;

  course: Course;
  contentsState: StateContent[] = [];
  lastContent: string;

  //Content listening and last second listened
  content: Content;
  position = 0;

  events: Event[] = [];

  constructor(course: Course, content: Content = null, second: number = 0) {
    // console.log("Contruct StateCourse",course);
    this.course = course;
    if (content == null) {
      this.content = course.contents[0];
    } else {
      this.content = content;
    }
    this.dateStart = new Date();
    this.dateLastListen = new Date();
  }

  fronJson(json: any) {
    const state = new StateCourse(null, null, 0);
  }

  isListenedContent(content: Content): boolean {
    var contentState = this.contentsState.filter(
      (state) => state.contentId === content.id
    );

    var isEnded = false;
    for (let i = 0; i < contentState.length; i++) {
      if (contentState[i].ended == true) {
        isEnded = true;
      }
    }
    // console.log('content state', contentState);

    if (contentState == null) {
      return false;
    }
    return isEnded;
  }
}
