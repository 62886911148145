<!-- pagination="true" -->
<swiper-container [slidesPerView]="2.6" [loop]="false">
  <swiper-slide *ngFor="let card of cards">
    <app-simple-square-card
      *ngIf="!loading"
      [courseID]="card.id"
      text="{{ card.name }}"
      imgURL="{{ card.icon }}"
    ></app-simple-square-card>
    <app-simple-square-card
      *ngIf="loading"
      [loading]="true"
    ></app-simple-square-card>
  </swiper-slide>
</swiper-container>
