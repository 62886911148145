import { Injectable } from '@angular/core';
import { StateCourse } from '../model/state.model';
import { Course } from '../model/course';
import { Content } from '../model/content';
import { ApiService } from './api.service';
import { User } from '../model/user.model';
import { Event } from '../model/event.model';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  state: StateCourse;

  constructor(
    private api: ApiService,
    private userService: UserService,
    private router: Router
  ) {}

  loadState(state: StateCourse) {
    if (state === undefined) {
      return null;
    }
    // console.log('loadState',state);
    this.state = state;
    // Order contentsState by updated date, first is the last updated
    // this.state.contentsState = this.state.contentsState.sort(
    //   (a, b) => b.dateLast.getTime() - a.dateLast.getTime()
    // );
    //console.log("Content State", this.state.contentsState);

    this.loadContentLast();
    /*
    this.state.content = this.getContentLast();
    //Load content last updated
    //const contentsActives = this.state.contentsState.filter( content => !content.ended);
    //console.log("Content Actives", contentsActives);
    //this.state.content = this.state.course.contents.find(x => x.id === contentsActives[0].contentId);
    this.state.position = this.state.contentsState[0].seconds;
    //Add default content
    if(this.state.content === undefined) {
      this.state.content = this.state.course.contents[0];
      this.state.position = 0;
    }
     */
  }

  addEvent(eventType: string, content: Content, data: string = null) {
    const event = new Event(eventType, content);
    switch (content.type) {
      case 'audio':
        event.seconds = parseInt(data);
        break;
      case 'question':
        event.answer = data;
        break;
    }
    this.state.events.push(event);
    // console.log('Add event',eventType,content);
    this.userService.saveEvent(this.state, event);
  }

  async loadContent(course: Course, content: Content, seconds: number = 0) {
    // console.log('Load content',course,content);
    //If course it's different from the current one, load it
    if (this.state === undefined || this.state.course.id !== course.id) {
      this.userService
        .getStateCourse(course, true)
        .then((state) => {
          this.state = state;
          // console.log('Loaded state',this.state);
          if (this.state.content.id !== content.id) {
            this.state.content = content;
            this.addEvent(Event.typeContentStart, content);
          }
        })
        .then(() => {
          this.state.content = content;
          this.state.position = seconds;
        });
    } else {
      this.state.content = content;
      this.state.position = seconds;
    }
  }

  createState(course: Course, content: Content = null, second: number = 0) {
    this.state = new StateCourse(course, content, second);
  }

  getCourse(): Course {
    return this.state.course;
  }

  getContent(): Content {
    return this.state.content;
  }

  loadNextContent(allowQuestion?: boolean) {
    const content = this.state.course.getNextContent(
      this.getContent(),
      allowQuestion
    );
    // console.log('Load next content',content);
    if (content === null) {
      //@todo: End course page and question
      // console.log('Last element');
      this.router.navigateByUrl(`/end/${this.state.course.id}`);
      return false;
    } else {
      this.loadContent(this.getCourse(), content);
      return true;
    }
  }

  /**
   * Return the content to continue listening or questing
   */
  getContentLast(): Content {
    this.state.contentsState.sort((a, b) => {
      if (a.dateLast < b.dateLast) {
        return -1;
      }
    });
    // console.log('lastContentPlayed', this.state.contentsState[0], this.state.contentsState);
    let content = this.state.course.contents.find(
      (x) => x.id === this.state.contentsState[0].contentId
    );

    if (content && this.state.contentsState[0].ended) {
      content = this.state.course.getNextContent(content);
    }
    if (content == null) {
      content = this.state.course.contents[0];
    }
    return content;
  }

  /**
   * Load the content to continue listening or questing
   */
  loadContentLast() {
    const content = this.getContentLast();
    this.loadContent(
      this.getCourse(),
      content,
      this.searchSecondsInState(content)
    );
  }

  getLastSecond() {
    return this.state.position;
  }

  searchSecondsInState(content: Content): number {
    const contentState = this.state.contentsState.find(
      (x) => x.contentId === content.id
    );
    return contentState ? contentState.seconds : 0;
  }
}
