import {ActivatedRoute, CanLoad, Route, Router, UrlSegment} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {Observable, of} from 'rxjs';
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ExternalLoginGuard implements CanLoad {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {

    this.authService.setToken(segments[2].path);

    this.router.navigateByUrl('/eh-course/'+segments[1].path);

    return of(true);
  }
}
