
<div class="lines-container">
  <div class="line"></div>
  <div class="text-container">
    <span class="text">o logueate con </span>
  </div>
  <div class="line"></div>
</div>

<div class="social-buttons">
  <!-- <ion-button color="white" expand="block" (click)="signInWith('facebook')">

    <ion-icon  color="dark" src="../../../assets/facebook_ic.svg"></ion-icon>
  </ion-button>
  <ion-button color="white" expand="block" (click)="signInWith('google')">
    <ion-icon  color="dark" src="../../../assets/google_ic.svg"></ion-icon>
  </ion-button>
  <ion-button color="white" expand="block" (click)="signInWith('apple')">
    <ion-icon  color="dark" src="../../../assets/cib_apple.svg"></ion-icon>
  </ion-button> -->
  <ion-button color="white" expand="block" (click)="loginWithGenExit()">
    <ion-icon  color="dark" src="../../../assets/new-logo-exit.svg"></ion-icon>
  </ion-button>
</div>
