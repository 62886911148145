import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type ModeType = 'primary' | 'secondary' | 'subtle' | 'text';

@Component({
  selector: 'app-eh-button',
  templateUrl: './eh-button.component.html',
  styleUrls: ['./eh-button.component.scss'],
})
export class EHButtonComponent implements OnInit {
  @Input() size = 'default';

  @Input() mode: ModeType = 'primary';
  @Input() text?;
  @Input() color = 'primary';
  @Input() iconName?;
  @Input() iconSlot = 'end';

  @Input() expand = false;
  @Input() disable = false;
  @Input() loading = false;

  @Output() btnClick = new EventEmitter<MouseEvent>();

  constructor() {}

  onClick() {
    if (!this.loading) {
      this.btnClick.emit();
    }
  }

  getBtnColor() {
    if (this.mode === 'subtle') {
      return 'danger';
    }
    if (this.disable) {
      return 'light-gray';
    }
    switch (this.mode) {
      case 'primary':
        return this.color;
      case 'secondary':
        return 'dark';

      case 'text':
        return 'dark';
    }
    return 'primary';
  }
  getTxtColor() {
    if (this.disable) {
      return 'dark-gray';
    }
    if (this.mode === 'primary') {
      return 'white';
    }
    return 'dark';
  }
  ngOnInit() {}
}
