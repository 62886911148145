import { Component } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-new-version-modal',
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title class="modal-title"
          >Hay una nueva versión disponible</ion-title
        >
      </ion-toolbar>
    </ion-header>
    <ion-content class="modal-content">
      <ion-card>
        <ion-card-content>
          <p class="modal-message">Descarga la última versión de Emotionhack</p>
          <ion-button
            expand="full"
            (click)="redirectToAppStore()"
            class="modal-button"
          >
            Actualizar
          </ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  `,
  styles: [
    `
      .modal-title {
        font-weight: bold;
        text-align: center;
      }

      .modal-content {
        padding: 16px;
      }

      .modal-message {
        text-align: center;
        margin-bottom: 16px;
      }

      .modal-button {
        --ion-background-color: var(--ion-color-primary);
        --ion-background-color-hover: var(--ion-color-primary-shade);
        --ion-background-color-activated: var(--ion-color-primary-tint);
        --ion-text-color: var(--ion-color-light);
      }
    `,
  ],
})
export class NewVersionModalComponent {
  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) {}

  redirectToAppStore() {
    const storeUrl = this.getStoreUrl(); // Get the app store/play store URL based on the platform

    if (storeUrl) {
      window.open(storeUrl, '_system'); // Open the URL in a new browser window/tab
    }

    this.closeModal();
  }

  private getStoreUrl(): string | null {
    if (this.platform.is('android')) {
      // Replace 'your-package-name' with the actual package name of your app on Google Play Store
      return 'https://play.google.com/store/apps/details?id=com.emotionhack&gl=ES';
    }

    if (this.platform.is('ios')) {
      // Replace 'your-app-id' with the actual app ID of your app on Apple App Store
      return 'https://apps.apple.com/us/app/emotionhack/id1618427364';
    }

    return null; // Return null if the platform is unsupported
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
