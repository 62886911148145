import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { AlertController, ModalController, Platform } from '@ionic/angular';
// import { SubscribeComponent } from '../modal/subscribe/subscribe.component';
import { Content } from '../model/content';
import { Course } from '../model/course';
import { StateCourse } from '../model/state.model';
import { PlayerPage } from '../pages/player/player.page';
import { ApiService } from '../services/api.service';
import { PlayerService } from '../services/player.service';
import { StateService } from '../services/state.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss'],
})
export class TabsPage {
  content: Content;
  course: Course;
  constructor(
    public platform: Platform,
    private userService: UserService,
    private stateService: StateService,
    private player: PlayerService,
    private modalController: ModalController,
    private router: Router
  ) {}

  play() {
    this.userService.getUser('').subscribe((res) => {
      // console.log(res);
      if (!res.subscribed) {
        this.player.openModal();
      } else {
        this.router.navigateByUrl('/player');
      }
    });
  }
}
