import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AutoLoginGuard implements CanLoad {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}
  canActivate(): Observable<boolean> {
    const token = this.authService.loadToken();
    // console.log('AutoLoginGuard',this.router.url, token);

    if (token !== null && token !== undefined) {
      // console.log('AutoLoginGuard',this.router.url, 'TO COURSES');
      this.router.navigateByUrl('/home');
      return of(false);
    } else {
      return of(true);
    }
  }
  canLoad(): Observable<boolean> {
    const token = this.authService.loadToken();
    // console.log('AutoLoginGuard',this.router.url, token);

    if (token !== null && token !== undefined) {
      // console.log('AutoLoginGuard',this.router.url, 'TO COURSES');
      this.router.navigateByUrl('/home');
      return of(false);
    } else {
      return of(true);
    }

    // return from(this.authService.loadToken()).pipe(
    //   map(token => {
    //     if(token !== null){
    //       this.router.navigateByUrl('/courses');
    //       return false;
    //     }else{
    //       return true;
    //     }
    //   }),
    // );
  }
}
