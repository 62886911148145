import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
})
export class ContentCardComponent implements OnInit {
  @Input()selected=true;
  @Input()progress=0;
  @Input()locked=true;
  @Input() title!: string;
  @Input() time!: string;


  constructor() { }


  get computedWidth(): string {
    return `calc(${this.progress}% - 25px)`;
  }

  ngOnInit() {}

}
