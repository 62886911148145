import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Media, MediaObject } from '@awesome-cordova-plugins/media/ngx';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Course } from '../model/course';
import { Content } from '../model/content';
import { Event } from '../model/event.model';
import { UserService } from './user.service';
import { StateService } from './state.service';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { MEDIA_STATUS } from '@awesome-cordova-plugins/media';
import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { ContentAnswer } from '../model/contentAnswer';
import { SubscribeComponent } from '../modal/subscribe/subscribe.component';
import { StateCourse } from '../model/state.model';
import { StateContent } from '../model/stateContent.model';
import { ApiService } from './api.service';

import { User } from '../model/user.model';
import { ContentNotAvailableComponent } from '../modal/content_not_available/content-not-available.component';

declare const gtag: Function;
declare const dataLayer: any[];
@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  course: Course;
  content: Content;
  courseState: StateCourse;
  user: User;
  currentContentState: StateContent;
  title = 'No audio';

  browserAudio: HTMLAudioElement;
  isPlayingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  duration: any = -1;
  displayDurationTotal: any = '00:00';
  isReady = false;
  mediaFile: MediaObject;
  isPlaying = false;
  isLoading = false;
  lastUpdatePosition = 0;
  lastUpdateTime = new Date();
  storageDirectory: any;
  position: any = 0;
  get_position_interval: any;
  is_in_play = false;
  get_duration_interval: any;
  display_position: any = '00:00';
  updateInterval = 5;
  savePositionInterval$: Subscription;
  handleMusic: {
    updateIsPlaying: any;
    create: any;
    subscribe: any;
    listen: any;
    updateElapsed: any;
  };
  isBrowser: boolean = false;
  seekAfterStart: undefined | number = undefined;
  positionInterval: any;
  constructor(
    public platform: Platform,
    private api: ApiService,
    private media: Media,
    private router: Router,
    private modalController: ModalController,
    private userService: UserService,
    private alertController: AlertController,
    private stateService: StateService,
    // private ga: GoogleAnalytics
    private musicControls: MusicControls
  ) {
    this.position = 0;
    this.isBrowser = !(
      this.platform.is('cordova') || this.platform.is('capacitor')
    );
    this.lastUpdateTime = new Date();
    if (!this.isBrowser) {
      this.handleMusic = this.musicControls;
    } else {
      this.browserAudio = new Audio();
      this.handleMusic = {
        create: () => {},
        listen: () => {},
        subscribe: () => ({ subscribe: () => {} }),
        updateElapsed: () => {},
        updateIsPlaying: () => {},
      };
    }
  }

  async loadContent(course: Course, content: Content, start: boolean = true) {
    // console.log('Player load content', course, content);
    if (this.isPlaying) {
      this.stop();
    }
    this.stateService.loadContent(course, content).then(() => {
      this.initContent();
    });
  }

  async initContent(start: boolean = false) {
    // debugger;
    // console.log(
    //   'Init content',
    //   this.stateService.getCourse(),
    //   this.stateService.getContent()
    // );

    this.isLoading = false;
    this.isPlaying = false;
    this.isPlayingSubject.next(false);
    this.course = this.stateService.getCourse();
    this.content = this.stateService.getContent();
    this.user = await this.userService.getUser('').toPromise();
    this.courseState = await this.userService.getStateCourse(this.course);
    this.currentContentState = this.courseState?.contentsState?.find(
      (content) => content.contentId === this.content.id
    );

    //Verify if it's a payment content
    if (!this.content.isFree() && !this.userService.isSubscribed()) {
      const modal = await this.modalController.create({
        // component: SubscribeComponent,
        component: ContentNotAvailableComponent,
        componentProps: {
          course: this.course,
        },
      });
      modal.present();
      // this.openModal();
      this.router.navigateByUrl('/eh-course/' + this.course.id);
      return;
    }

    if (this.content.type === 'audio') {
      // console.log('Player init content', this.course, this.content);
      this.prepareMedia();
      this.musicControlPrepare();
      // console.log("Player",this);
      if (this.isBrowser) {
        if (this.currentContentState) {
          this.setPosition(this.currentContentState.seconds);
        } else {
          this.setPosition(0);
        }
      } else {
        this.seekAfterStart = this.currentContentState?.seconds || 0;
      }
      if (start) {
        this.play();
      }
    }
    if (this.content.type === 'question') {
      if (this.router.url.indexOf('/player') > -1) {
        this.router.navigateByUrl('/player');
      } else {
        this.isReady = true;
      }
    }
  }

  getIsPlaying(): Observable<boolean> {
    return this.isPlayingSubject.asObservable();
  }

  openModal() {
    this.modalController
      .create({
        // component: SubscribeComponent,
        component: ContentNotAvailableComponent,
        // componentProps: {
        //   course: this.course,
        // },
      })
      .then((modal) => {
        modal.present();
      });
  }
  updatePosition() {
    this.mediaFile.getCurrentPosition().then((_) => {
      this.updateTime();
    });
  }

  startPositionUpdate() {
    this.positionInterval = setInterval(this.updatePosition.bind(this), 1000);
  }

  stopPositionUpdate() {
    if (this.positionInterval) {
      clearInterval(this.positionInterval);
    }
  }
  updateTime() {
    // console.log('EEEEE 1', this.lastUpdatePosition, this.position);
    if (
      this.lastUpdatePosition + this.updateInterval <= this.position ||
      this.position < this.lastUpdatePosition
    ) {
      const currentUpdateTime = new Date();
      // console.log(
      //   'EEEEE 2',
      //   currentUpdateTime.getTime() - this.lastUpdateTime.getTime()
      // );
      if (
        currentUpdateTime.getTime() - this.lastUpdateTime.getTime() >=
        this.updateInterval * 1000
      ) {
        this.lastUpdatePosition = this.position;
        this.lastUpdateTime = currentUpdateTime;
        this.stateService.addEvent(
          Event.typeContentPlay,
          this.content,
          this.position
        );
      }
    }
  }
  prepareMedia() {
    this.duration = this.content.duration;
    this.displayDurationTotal = this.toHHMMSS(this.duration);

    if (this.isBrowser) {
      this.browserAudio.src = this.content.audio;

      // Event listener for 'play'
      this.browserAudio.addEventListener('play', () => {
        this.isPlaying = true;
        this.isPlayingSubject.next(true);
      });

      // Event listener for 'pause'
      this.browserAudio.addEventListener('pause', () => {
        this.isPlaying = false;
        this.isPlayingSubject.next(false);
      });

      this.browserAudio.addEventListener('ended', () => {
        this.isPlaying = false;
        this.isPlayingSubject.next(false);
        this.end();
      });
      this.browserAudio.addEventListener('timeupdate', () => {
        if (
          !this.browserAudio.paused &&
          !this.browserAudio.ended &&
          this.browserAudio.currentTime > 0
        ) {
          this.updateTime();
        }
      });
    } else {
      this.mediaFile = this.media.create(this.content.audio);

      this.mediaFile.onStatusUpdate.subscribe((status) => {
        // console.log('Status update', status);
        switch (status) {
          case MEDIA_STATUS.NONE:
            this.isPlaying = false;
            this.isPlayingSubject.next(false);
            this.stopPositionUpdate();
            break;
          case MEDIA_STATUS.STARTING:
            this.isPlaying = false;
            this.isPlayingSubject.next(false);
            this.isLoading = true;
            break;
          case MEDIA_STATUS.RUNNING:
            if (this.seekAfterStart !== undefined) {
              this.setPosition(this.seekAfterStart);
              this.seekAfterStart = undefined;
            }
            this.isPlaying = true;
            this.isPlayingSubject.next(true);
            this.isLoading = false;
            this.handleMusic.updateIsPlaying(true);
            this.startPositionUpdate();
            this.updateTime();
            this.savePosition();
            break;
          case MEDIA_STATUS.PAUSED:
            // this.stateService.addEvent(Event.typeContentPause, this.content);
            this.savePositionInterval$.unsubscribe();
            this.isPlaying = false;
            this.isPlayingSubject.next(false);
            this.handleMusic.updateIsPlaying(false);
            this.stopPositionUpdate();
            break;
          case MEDIA_STATUS.STOPPED:
            this.stopPositionUpdate();
            this.stateService.addEvent(Event.typeContentEnd, this.content);
          default:
            this.savePositionInterval$.unsubscribe();
            this.isPlaying = false;
            this.stopPositionUpdate();
            this.isPlayingSubject.next(false);
            this.handleMusic.updateIsPlaying(false);
            break;
        }
      });
      this.mediaFile.onSuccess.subscribe(() => {
        // console.log('ON Success');
      });
      this.mediaFile.onError.subscribe((error) => {
        console.error('ON Error', JSON.stringify(error));
      });
    }

    // console.log('Start Vgpastor play');
    this.isReady = true;
    this.getAndSetCurrentAudioPosition();
    // this.play();
  }

  getAndSetCurrentAudioPosition() {
    const diff = 1;
    const self = this;
    this.get_position_interval = setInterval(() => {
      const lastPosition = self.position;
      if (this.isBrowser) {
        const position = self.browserAudio.currentTime;
        if (position >= self.duration) {
          self.end();
          return;
        }
        // Uncomment the following if you want to ensure position doesn't jump unexpectedly
        // if (Math.abs(lastPosition - position) >= diff) {
        //    self.browserAudio.currentTime = lastPosition;
        // } else {
        self.position = position;
        this.display_position = this.toHHMMSS(self.position);
      } else {
        self.mediaFile.getCurrentPosition().then((position) => {
          if (position < 0) {
            return;
          }

          if (position >= self.duration) {
            self.end();
            return;
          }
          // if (Math.abs(lastPosition - position) >= diff) {
          //   // set position
          //   self.mediaFile.seekTo(lastPosition * 1000);
          // }else{
          self.position = position;
          this.display_position = this.toHHMMSS(self.position);
          // }
        });
      }
    }, 100);
  }

  setPosition(seconds: number) {
    if (this.platform.is('cordova') || this.platform.is('capacitor')) {
      this.mediaFile.seekTo(seconds * 1000);
    } else {
      this.browserAudio.currentTime = seconds;
    }
    this.position = seconds;
    this.display_position = this.toHHMMSS(this.position);
  }

  savePosition() {
    const interval1 = interval(10000);
    this.savePositionInterval$ = interval1.subscribe(() => {
      this.mediaFile.getCurrentPosition().then((position) => {
        if (position >= 0 && position < this.duration) {
          // this.stateService.addEvent(
          //   Event.typeContentListening,
          //   this.content,
          //   position
          // );
        }
      });
    });
  }

  async play() {
    // console.log('Play', this.media, this.mediaFile, this.isPlaying);
    // this.ga.trackEvent('player', 'play', this.content.name);

    if (!this.isPlaying && !this.isLoading) {
      this.isLoading = true;

      if (this.position === 0) {
        if (this.currentContentState?.ended) {
          this.api
            .sendAnalytics({
              event: 'RePlay Content',
              course_content: this.content.name,
              user_email: this.user.email,
              course_name: this.course.name,
            })
            .subscribe(
              (response) => {
                // console.log('Analytics data sent successfully', response);
              },
              (error) => {
                console.error('Error sending analytics data', error);
              }
            );
        } else {
          this.api
            .sendAnalytics({
              event: 'Play Content',
              course_content: this.content.name,
              user_email: this.user.email,
              course_name: this.course.name,
            })
            .subscribe(
              (response) => {
                // console.log('Analytics data sent successfully', response);
              },
              (error) => {
                console.error('Error sending analytics data', error);
              }
            );
        }
      }
      if (this.isBrowser) {
        this.isLoading = false;
        this.isPlaying = true;
        this.isPlayingSubject.next(true);
        this.browserAudio.play();
        return;
      } else {
        this.mediaFile.play({ playAudioWhenScreenIsLocked: true });
        this.mediaFile.setVolume(1.0);
      }
    }
  }

  pause() {
    // console.log('Pause', this.media, this.mediaFile, this.isPlaying);
    // this.ga.trackEvent('player', 'pause', this.content.name);
    if (!this.isPlaying || this.isLoading) {
      return;
    }
    if (this.isPlaying) {
      if (this.isBrowser) {
        this.browserAudio.pause();
      } else {
        this.mediaFile.pause();
      }
    }
  }
  async endForNew() {
    this.savePositionInterval$.unsubscribe();
    this.stop();
    // this.initContent(true);
  }
  stop() {
    // console.log('Stop', this.media, this.mediaFile, this.isPlaying);
    // this.ga.trackEvent('player', 'stop', this.content.name);
    if (this.isReady && this.content.type === 'audio') {
      if (this.isBrowser) {
        this.browserAudio.pause();
        this.browserAudio.currentTime = 0;
      } else {
        this.mediaFile.stop();
        this.mediaFile.release();
      }
    }
    clearInterval(this.get_position_interval);
    this.position = 0;
  }

  controlSeconds(action) {
    const step = 15;
    let newPosition = 0;
    switch (action) {
      case 'back':
        newPosition = this.position < step ? 0.001 : this.position - step;
        break;
      case 'forward':
        newPosition =
          this.position + step < this.duration
            ? this.position + step
            : this.duration;
        break;
      default:
        break;
    }
    this.setPosition(newPosition);
  }

  answer(answer: ContentAnswer): boolean {
    // console.log('Answer', answer, this.content.answers);
    if (answer.valid) {
      // this.stateService.addEvent(
      //   Event.typeContentAnswerCorrect,
      //   this.content,
      //   answer.answer
      // );
      setTimeout(() => {
        this.end();
      }, 2000);
      return true;
    } else {
      // this.stateService.addEvent(
      //   Event.typeContentAnswerError,
      //   this.content,
      //   answer.answer
      // );
      this.presentAlert(
        '¿Estas seguro?',
        'La respuesta es incorrecta, deberías volver a escuchar el audio.'
      );
      return false;
    }
    /*if (this.content.answers.find(answer => answer === this.content.options.indexOf(answerText))) {
      this.state.addEvent(Event.typeContentAnswerCorrect, this.content, answerText);
      setTimeout(() => {
        this.end();
      }, 1000);
      return true;
    } else {
      this.state.addEvent(Event.typeContentAnswerError, this.content, answerText);
      this.presentAlert('¿Estas seguro?', 'La respuesta es incorrecta, deberías volver a escuchar el audio.');
      return false;
    }*/
  }

  async presentAlert(header: string = '', message: string = '') {
    const alert = await this.alertController.create({
      cssClass: 'alert-class',
      header,
      // subHeader: 'Subtitle',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  private toHHMMSS(secs) {
    const secNum = parseInt(secs, 10);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [minutes, seconds]
      .map((v) => (v < 10 ? '0' + v : v))
      .filter((v, i) => v !== '00' || i >= 0)
      .join(':');
  }
  private end() {
    // console.log('End');
    // this.ga.trackEvent('player', 'end', this.content.name);
    this.stateService.addEvent(Event.typeContentEnd, this.content); //Keep
    if (this.content.type === 'audio') {
      this.api
        .sendAnalytics({
          event: 'Finished Content',
          course_content: this.content.name,
          user_email: this.user.email,
          course_name: this.course.name,
        })
        .subscribe(
          (response) => {
            // console.log('Analytics data sent successfully', response);
          },
          (error) => {
            console.error('Error sending analytics data', error);
          }
        );
      if (this.savePositionInterval$) {
        this.savePositionInterval$.unsubscribe();
      }
      this.stop();
    }
    const nextContent = this.stateService.loadNextContent(true);
    if (!nextContent) {
      this.stop();
    } else {
      this.initContent(true);
    }
  }

  private musicControlPrepare() {
    this.handleMusic.create({
      track: this.content.name, // optional, default : ''
      artist: this.course.name, // optional, default : ''
      cover: 'https://app.emotionhack.com/assets/launcher.png', // optional, default : nothing
      // cover can be a local path (use fullpath 'file:///storage/emulated/...', or only 'my_image.jpg' if my_image.jpg is in the www folder of your app)
      //           or a remote url ('http://...', 'https://...', 'ftp://...')
      isPlaying: false, // optional, default : true
      // dismissable : true,                         // optional, default : false
      elapsed: 0, // Initialize elapsed time to 0
      duration: this.duration, // Set the total duration
      // hide previous/next/close buttons:
      hasPrev: true, // show previous button, optional, default: true
      hasNext: true, // show next button, optional, default: true
      // hasClose  : true,       // show close button, optional, default: false

      // iOS only, optional
      album: 'EmotionHack ' + this.content.name, // optional, default: ''
      // duration : 60, // optional, default: 0
      // elapsed : 10, // optional, default: 0
      hasSkipForward: true, // show skip forward button, optional, default: false
      hasSkipBackward: true, // show skip backward button, optional, default: false
      skipForwardInterval: 15, // display number for skip forward, optional, default: 0
      skipBackwardInterval: 15, // display number for skip backward, optional, default: 0
      // hasScrubbing: false, // enable scrubbing from control center and lockscreen progress bar, optional

      // Android only, optional
      // text displayed in the status bar when the notification (and the ticker) are updated, optional
      ticker: 'EmotionHack ' + this.content.name,
      // All icons default to their built-in android equivalents
      playIcon: 'media_play',
      pauseIcon: 'media_pause',
      prevIcon: 'media_prev',
      nextIcon: 'media_fast_forward',
      closeIcon: 'media_close',
      notificationIcon: 'notification',
    });
    this.handleMusic.subscribe().subscribe((action) => {
      // function events(action) {
      const message = JSON.parse(action).message;
      switch (message) {
        case 'music-controls-next':
          this.controlSeconds('forward');
          break;
        case 'music-controls-previous':
          this.controlSeconds('back');
          break;
        case 'music-controls-pause':
          this.pause();
          break;
        case 'music-controls-play':
          this.play();
          break;
        case 'music-controls-destroy':
          // Do something
          break;

        // External controls (iOS only)
        case 'music-controls-toggle-play-pause':
          this.toggle();
          break;
        case 'music-controls-seek-to':
          const seekToInSeconds = JSON.parse(action).position;
          this.handleMusic.updateElapsed({
            duration: this.duration, // Update total duration
            elapsed: seekToInSeconds,
            isPlaying: true,
          });
          //@todo: Verify
          this.setPosition(seekToInSeconds);
          this.play();
          break;
        case 'music-controls-skip-forward':
          this.controlSeconds('forward');
          break;
        case 'music-controls-skip-backward':
          this.controlSeconds('back');
          break;

        // Headset events (Android only)
        // All media button events are listed below
        case 'music-controls-media-button':
          this.toggle();
          break;
        case 'music-controls-headset-unplugged':
          // Do something
          break;
        case 'music-controls-headset-plugged':
          // Do something
          break;
        default:
          break;
      }
      // }
    });
    setInterval(() => {
      if (this.isPlaying) {
        // Get the current elapsed time
        const elapsed = this.position;
        // Update the notification widget with the current elapsed time
        this.handleMusic.updateElapsed({
          duration: this.duration, // Update total duration
          elapsed: this.position,
          isPlaying: true,
        });
      }
    }, 1000); // Update every second
    this.handleMusic.listen(); // activates the observable above
  }

  private toggle() {
    if (this.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  }
}
