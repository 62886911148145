import { ContentAnswer } from './contentAnswer';
import { Course } from './course';

export class Content {
  id: string;
  order: number;
  type = '';
  name = '';
  status = '';
  updatedAt: any;

  question: string;
  answers: ContentAnswer[];

  audio: string;
  length: number;
  duration: number;
  parent: boolean | string;

  free: boolean;
  totalTime: number;

  lastProgress: number = 0;

  constructor(result: Content) {
    this.id = result.id;
    this.name = result.name;
    this.order = result.order;
    this.type = result.type;
    this.status = result.status;
    this.updatedAt = result.updatedAt;

    this.question = result.question;
    this.answers = result.answers;

    this.audio = result.audio;
    this.duration = result.duration;
    this.parent = result.parent;
    this.free = result.free;
  }

  public minutes() {
    if (typeof this.duration !== 'number' || this.duration < 0) {
      return '00:00';
    }

    const hours = Math.floor(this.duration / 3600);
    const minutes = Math.floor((this.duration % 3600) / 60);
    const seconds = Math.floor(this.duration % 60);

    return this.padTime(minutes) + ':' + this.padTime(seconds);
  }
  public totalMinutes() {
    if (typeof this.totalTime !== 'number' || this.totalTime < 0) {
      return '00:00';
    }

    const minutes = Math.floor((this.totalTime % 3600) / 60);
    const seconds = Math.floor(this.totalTime % 60);

    return this.padTime(minutes) + ':' + this.padTime(seconds);
  }
  calculateTotalTime(contents: any[], parentId: string): number {
    let totalTime = 0;

    for (const content of contents) {
      if (content.parent === parentId) {
        if (content.type === 'audio') {
          totalTime += content.duration;
        }
        totalTime += this.calculateTotalTime(contents, content.id);
      }
    }

    return totalTime;
  }
  calculateTotalProgress(course: Course, parentId: string): number {
    const contents = course.contents;
    let totalProgress = 0;
    for (const content of contents) {
      if (content.parent === parentId) {
        if (content.type === 'audio') {
          totalProgress += course.progressDict[content.id];
        }
        totalProgress += this.calculateTotalProgress(course, content.id);
      }
    }
    return totalProgress;
  }
  padTime(t) {
    return t < 10 ? '0' + t : t;
  }

  isFree() {
    return this.free;
  }
}
