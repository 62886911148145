import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-eh-custom-password-input',
  templateUrl: './eh-custom-password-input.component.html',
  styleUrls: ['./eh-custom-password-input.component.scss'],
})
export class EhCustomPasswordInputComponent implements OnInit {
  @Input() isPasswordConfirm = false;
  @Input() iconName: string | null = null;
  @Input() disabled = false;
  @Input() passwordValue = '';
  @Input() confirmPasswordValue = '';
  @Input() hasError = true;
  @Input() hasText = false;
  @Input() labelPassword = 'Contraseña';
  @Input() labelConfirm = 'Repite la contraseña';
  @Output() passwordValueChange = new EventEmitter<string>();
  @Output() confirmPasswordValueChange = new EventEmitter<string>();
  @Output() isValid = new EventEmitter<boolean>();
  inputFormGroup!: FormGroup;
  focused = true;
  filled = false;
  showPassword = false;
  showConfirmPassword = false;
  togglePassword() {
    this.showPassword = !this.showPassword;
  }
  toggleShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  ngOnInit() {
    const passwordPattern =  /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,30}$/;
    const passControl = new FormControl(this.passwordValue, [
      Validators.required,
      Validators.pattern(passwordPattern),
    ]);

    if (this.isPasswordConfirm) {
      const confirmPassControl = new FormControl(
        this.confirmPasswordValue,
        Validators.required
      );

      const passwordConfirmValidator = () => {
        const password = passControl.value;
        const confirmPassword = confirmPassControl.value;

        return password === confirmPassword ? null : { passwordMismatch: true };
      };

      confirmPassControl.setValidators([
        Validators.pattern(passwordPattern),
        Validators.required,
        passwordConfirmValidator,
      ]);

      if (this.disabled) {
        passControl.disable();
        confirmPassControl.disable();
      }

      this.inputFormGroup = new FormGroup({
        password: passControl,
        confirm: confirmPassControl
      });
    } else {
      this.inputFormGroup = new FormGroup({
        password: passControl,
      });

      if (this.disabled) {
        passControl.disable();
      }
    }

    this.inputFormGroup.valueChanges.subscribe((newValue: any) => {
      // console.log(newValue);
      this.passwordValueChange.emit(newValue.password);
      this.confirmPasswordValueChange.emit(newValue.confirm);
    });

    this.inputFormGroup.get('password')?.valueChanges.subscribe(() => {
      this.updateConfirmPasswordValidation();
    });
    this.inputFormGroup.statusChanges.subscribe((status) => {
      // console.log(status);
      this.isValid.emit(status === 'VALID');
    });
  }

  updateConfirmPasswordValidation() {
    if (!this.isPasswordConfirm) {
      return;
    }
    const confirmControl = this.inputFormGroup.get('confirm');

    if (confirmControl) {
      confirmControl.updateValueAndValidity(); // Trigger the validation
    }
  }
}
