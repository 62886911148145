<ion-toolbar class="ion-text-center">
  <ion-buttons slot="start">
    <ion-back-button></ion-back-button>
    <ion-icon name="close" slot="icon-only" (click)="dismiss()"></ion-icon>
  </ion-buttons>
  <ion-title>Configuración</ion-title>
</ion-toolbar>

<ion-content>
  <!--  If account exist-->
  <div>
    <!-- <ion-button *ngIf="isSubscribed()" expand="block" class="white" (click)="subscriptionCancel()">Cancelar suscripción</ion-button> -->

    <ion-button expand="block" class="white" href="mailto:info@emotionhack.com"
      >Contáctanos</ion-button
    >

    <ion-button expand="block" class="danger" (click)="deleteAccount()"
      >Borrar cuenta</ion-button
    >
  </div>
</ion-content>

<ion-footer>
  <a href="https://www.emotionhack.com/politica-de-privacidad" target="_blank"
    >Política de Pagos</a
  ><br />
  <a href="https://www.emotionhack.com/politica-de-cookies" target="_blank"
    >Política de privacidad</a
  ><br />
  <a href="https://www.emotionhack.com/politica-de-privacidad" target="_blank"
    >Términos y condiciones</a
  >
</ion-footer>
