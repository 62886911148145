import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CaleidaApiService {
  httpOptions = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  private baseUrl = 'https://maher-api.emotionhack.com/api/rafa';

  constructor(private http: HttpClient) { }

  // Fetches the first chat ID based on a session ID prefix
  getChatId(sessionId: string): Observable<string> {
    const url = `${this.baseUrl}/chats/records?suid__startswith=${btoa(sessionId)}`;//bWFoZXIuc2VyYXdhbi4xOTk4JTQwZ21haWwuY29t
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response.results[0]?.id)
    );
  }

  // Fetches messages for a given chat ID, with optional offset and limit
  fetchMessages(chatId: string, offset: number = 0, limit: number = 5): Observable<any> {
    const url = `${this.baseUrl}/chats/records/${chatId}/messages?offset=${offset}&limit=${limit}`;
    return this.http.get(url, this.httpOptions);
  }

  // Sends a message to get an API response, adjusted to work through the proxy
  getApiResponse( message: string,email,name): Observable<any> {
    return new Observable(observer => {
      const verifyKey='35a937a81d6d4980ad0167255c823adc';
      const projectId='64a98fdb-5ca6-4d40-996b-d1552f1aa15f';
      const payload = {
        message: {
          info: {
            user: {
              name,
              email
            },
            identity: 'id'
          },
          session: btoa(email),
          lang: 'es',
          id: 'some',
          type: 'Text',
          text: message,
          role: 'User'
        }
      };
      // Convert payload to JSON string then to Base64 
      const base64Payload = btoa(JSON.stringify(payload));
      const url = `https://bots.api.caleida.io/api/v1/web/get_response/${projectId}/?verify_key=${verifyKey}&payload=${base64Payload}`;

      const eventSource = new EventSource(url);

      eventSource.onerror = (error) => {
        observer.error(error);
        eventSource.close();
      };
      // eventSource.addEventListener('chat.init', (e) => {
      //   const data = JSON.parse(e.data);
      // });

      eventSource.addEventListener('close', () => {
        observer.complete();
        eventSource.close();
      });

      eventSource.addEventListener('chat.msg_chunk', (event) => {
        try {
          // Parse the JSON string to an object
          const data = JSON.parse(event.data);

          // Notify the observer with the parsed data
          observer.next(data.chunk);
        } catch (error) {
          console.error('Error parsing chat.msg_chunk data:', error);
          // Handle parsing error (optional)
          // observer.error(error);
        }
      });
    });
  }
}
