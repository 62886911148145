/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/component-selector */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

type ModeType = 'email' | 'tel' | 'username' | 'message' | 'title';
@Component({
  selector: 'eh-custom-input',
  templateUrl: './eh-custom-input.component.html',
  styleUrls: ['./eh-custom-input.component.scss'],
})
export class EhCustomInput implements OnInit {
  @Input() iconName: string | null = null; // Input to optionally set the icon's name.
  @Input() mode!: ModeType;
  @Input() disabled = false;
  @Input() value = '';
  @Input() hasText = false;
  @Input() hasError = true;
  @Input() label: string;
  @Input() messageCharLimit = 300;
  @Output() valueChange = new EventEmitter<string>();
  @Output() isValid = new EventEmitter<boolean>();

  inputFormGroup!: FormGroup;
  focused = true;
  filled = false;

  getLabel() {
    if (!!this.label) {
      return this.label;
    }
    return this.mode;
  }
  getType() {
    if (this.mode === 'username') {
      return 'text';
    }

    return this.mode;
  }

  getHelperText() {
    switch (this.mode) {
      case 'email':
        return 'Ingresa una dirección de correo electrónico válida.';
      case 'tel':
        return 'Ingresa tu número de teléfono. Por ejemplo, +1 123-456-7890';
      case 'username':
        return 'Ingresa tu nombre. Por ejemplo, Juan Pérez';

      default:
        return '';
    }
  }

  getErrorText() {
    switch (this.mode) {
      case 'email':
        return 'Ese email no es válido. Por favor, por una dirección de email válida';
      case 'tel':
        return 'Formato de número de teléfono no válido. Asegúrese de que esté en el formato 123456789 o +34123456789';
      case 'username':
        return 'Introduce tu nombre';
      case 'message':
        return 'Introduce un mensaje';
        case 'title':
          return 'Introduce un título';
      default:
        return 'Invalid input.';
    }
  }
  async initializeFormControl() {
    const inputControl = new FormControl(this.value, Validators.required);
    const phonePattern = /^(\+\d{11}|\d{9})$/;
    // eslint-disable-next-line max-len
    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    switch (this.mode) {
      case 'email':
        inputControl.setValidators([
          Validators.pattern(emailPattern),
          Validators.required,
          Validators.maxLength(50),
        ]);
        break;
      case 'tel':
        inputControl.setValidators([
          Validators.pattern(phonePattern),
          Validators.required,
        ]);
        break;
      case 'message':
        inputControl.setValidators([
          Validators.required,
          Validators.maxLength(this.messageCharLimit),
        ]);
        break;
    }

    this.inputFormGroup = new FormGroup({
      input: inputControl,
    });

    this.inputFormGroup
      .get('input')
      ?.valueChanges.subscribe((newValue: string) => {
        this.valueChange.emit(newValue);
      });

    this.inputFormGroup.get('input')?.statusChanges.subscribe((status) => {
      this.isValid.emit(status === 'VALID');
    });

    if (this.disabled) {
      this.inputFormGroup.get('input')?.disable();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && changes.value.currentValue !== undefined) {
      this.initializeFormControl();
    }
  }

  ngOnInit() {
    this.initializeFormControl();
  }
}
