import {StateCourse} from './state.model';
import {Course} from './course';
import {StateContent} from "./stateContent.model";

export class User{
  id: string;
  name: string;
  email: string;
  profilePicture: string;
  states: StateCourse[] = [];
  subscribed = false;

}
