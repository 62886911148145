import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import {
  catchError,
  finalize,
  switchMap,
  filter,
  take,
  map,
} from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  // Used for queued API calls while refreshing tokens
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isRefreshingToken = false;
  token = null;

  constructor(
    private authenticationService: AuthenticationService,
    private toastCtrl: ToastController,
    private router: Router
  ) {
    // this.authenticationService.getToken().then((token) => this.token = token);
  }

  // Intercept every HTTP call
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if we need additional token logic or not
    if (!this.isInBlockedList(request.url)) {
      return next.handle(request);
    }

    const token = this.authenticationService.loadToken();
    // return this.authenticationService.loadToken().pipe(
    //   switchMap((token: string) => {
    let authReq;
    // console.log('Interceptor: ' + token);
    if (token) {
      // If we have a token, add it to the request
      authReq = this.addToken(request);
    } else {
      return this.handle401Error(request, next);
    }
    return next.handle(authReq).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 400:
              return this.handle400Error(err);
            case 401:
              return this.handle401Error(request, next);
            default:
              this.handleError(err);
          }
        } else {
          this.handleError(err);
        }
      })
    );
    //   }),
    // );
  }

  // Filter out URLs where you don't want to add the token!
  private isInBlockedList(url: string): boolean {
    // Example: Filter out our login and logout API call
    if (
      url.startsWith(environment.apiUrl) &&
      !url.startsWith(environment.apiUrl + '/auth') &&
      !url.startsWith(environment.apiUrl + '/version')&&
      !url.startsWith(environment.apiUrl + '/user/confirm-delete')&&
      !url.startsWith(environment.apiUrl + '/genexit')


    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Add our current access token from the service if present
   * @param req
   * @private
   */
  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.authenticationService.loadToken();
    // console.log("LOAD TOKEN: ", token);
    if (token) {
      return req.clone({
        headers: new HttpHeaders({
          'X-Authorization': `Bearer ${token}`,
        }),
      });
    } else {
      return req;
    }
  }

  // We are not just authorized, we couldn't refresh token
  // or something else along the caching went wrong!
  private async handle400Error(err) {
    // Potentially check the exact error reason for the 400
    // then log out the user automatically
    const toast = await this.toastCtrl.create({
      message: 'Logged out due to authentication mismatch',
      duration: 2000,
    });
    toast.present();
    // this.authenticationService.logout();
    return throwError(err);
  }

  // Indicates our access token is invalid, try to load a new one
  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    // Check if another call is already using the refresh logic
    console.error('Error 401', request);
    // debugger;
    if (!this.isRefreshingToken) {
      // Set to null so other requests will wait
      // until we got a new token!
      this.tokenSubject.next(null);
      this.isRefreshingToken = true;
      this.authenticationService.currentAccessToken = null;

      // First, get a new access token
      this.authenticationService.getNewAccessToken().subscribe(
        (data) => {
          // console.log("New Token", data);
          if (data == null) {
            return this.router.navigate(['/sign-up']);
            return of(null);
          }
          // console.info("Token reloaded", data);
          this.authenticationService.storeAccessToken(
            data.token,
            data.refreshToken
          );
          return next.handle(this.addToken(request));
        },
        (err) => {
          console.error({ err });
          this.authenticationService.logout();
          this.router.navigate(['/sign-up']);
          return of(null);
        },
        () => {
          this.isRefreshingToken = false;
          if (this.authenticationService.loadToken() == null) {
            this.router.navigate(['/sign-up']);
          }
        }
      );

      // return this.authenticationService.getNewAccessToken().pipe(
      //   switchMap((tokens: any) => {
      //     if (tokens) {
      //       console.log('Refresh token', tokens);
      //       // Store the new token
      //       return this.authenticationService.storeAccessToken(tokens.token, tokens.refreshToken).pipe(
      //         switchMap(_ => {
      //           // Use the subject so other calls can continue with the new token
      //           this.tokenSubject.next(tokens.token);
      //
      //           // Perform the initial request again with the new token
      //           return next.handle(this.addToken(request));
      //         })
      //       );
      //     } else {
      //       // No new token or other problem occurred
      //       this.router.navigate(['/main']);
      //       return of(null);
      //     }
      //   }),
      //   finalize(() => {
      //     // Unblock the token reload logic when everything is done
      //     this.isRefreshingToken = false;
      //   })
      // );
    } else {
      // "Queue" other calls while we load a new token
      return this.tokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) =>
          // Perform the request again now that we got a new token!
          next.handle(this.addToken(request))
        )
      );
    }
  }

  private handleError(err) {
    this.toastCtrl
      .create({
        header: 'Error HTTP',
        message: err.message,
        keyboardClose: true,
        duration:2000
      })
      .then((toast) => toast.present());
    // this.authenticationService.logout();
    return of(null);
  }
}
