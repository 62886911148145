import { GenExitAuthService } from './../../services/GenExitAuthService';
import { NavController } from '@ionic/angular';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-social-signin',
  templateUrl: './social-sign-in.component.html',
  styleUrls: ['./social-sign-in.component.scss'],
})
export class SocialSignInComponent {
  @Output() signIn: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private navCtrl: NavController,
    private authService: GenExitAuthService // Inject your authentication service
  ) {}


  signInWith(provider: string) {
    this.signIn.emit(provider);
  }

  loginWithGenExit() {
    // Call your authentication service method to initiate the GenExit login process
    this.authService.loginWithGenExit()
      .then(() => {
        // Redirect to the desired page after successful login
        this.navCtrl.navigateRoot('/home');
      })
      .catch(error => {
        // Handle any errors that occur during the login process
        console.error('Error logging in with GenExit:', error);
      });
}
}
