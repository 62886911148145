import {Content} from "./content";

export class Event{

  public static typeContentStart = 'content:start';
  public static typeContentPlay = 'content:play';
  public static typeContentListening = 'content:listening';
  public static typeContentPause = 'content:pause';
  public static typeContentEnd = 'content:end';
  public static typeContentAnswerError = 'content:answer:error';
  public static typeContentAnswerCorrect = 'content:answer:correct';


  type: string;
  contentId: string;
  contentType: string;
  date: Date;

  seconds: number;
  answer: string;

  constructor(type: string, content: Content){
    this.type = type;
    this.date = new Date();
    this.contentId = content.id;
    this.contentType = content.type;
  }

}
