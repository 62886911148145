<div class="card">
  <img
    src={{imageURL}}
    alt="Profile Image"
    class="image"
  />
  <div class="test">
  <div class="content">
    <span class="text2">{{course}}</span>

    <div class="title">{{content}}</div>

    <span class="text2">{{time}}</span>
    <div *ngIf="progress!==0" class="progress-bar-empty"></div>
    <div *ngIf="progress!==0" class="progress-bar-full" [style.width.%]="progress"></div>
  </div>
  <div class="icon-button">
    <ion-icon  name="play"></ion-icon>
  </div>
</div>

</div>

