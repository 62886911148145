<div *ngIf="!loading" class="card ion-activatable">
  <ion-ripple-effect></ion-ripple-effect>
  <img
    src={{imageURL}}
    alt="Course Image"
    class="image"
  />
  <div class="content">
    <div class="title">{{courseTitle}}</div>
    <div class="subtitle">
        <ion-icon  name="volume-medium" class="icon2"></ion-icon>
        <span class="text">{{time}}</span>
       
    </div>
    <span class="text2">{{courseDescription}}</span>
  </div>

</div>

<div *ngIf="loading" class="card">
  <!-- <img
    src="../../../assets\Frame 7331.svg"
    alt="Course Image"
    class="image"
  /> -->
  <div class="image-loading">
    <div class="static-content"></div>
  </div>

  <div style="width: 80%; display: block;">
    <ion-skeleton-text [animated]="true" style="width: 50%; height: 20px;"></ion-skeleton-text> 

    <ion-skeleton-text [animated]="true" style="width: 40%; height: 10px;"></ion-skeleton-text> 
    
    <ion-skeleton-text [animated]="true" style="width: 100%; height: 20px;"></ion-skeleton-text> 
  </div>
</div>
