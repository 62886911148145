import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenExitAuthService {
  httpOptions = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  loginWithGenExit(): Promise<void> {
    // eslint-disable-next-line max-len
    const url = 'https://genexit.org/auth/oauth?client_id=f83924bb-16be-4f6b-a72e-2100127b3c6c&redirect_uri=https%3A%2F%2Fapp.emotionhack.com%2Fgen-exit-login&response_type=code&grant_type=authorization_code&state=login';
    // Change '_blank' to '_self' to open in the same tab
    window.location.href = url;

    // Resolve the promise after redirection (optional)
    return new Promise<void>((resolve, reject) => {
      console.log('Gen Exit Done');
      resolve();
    });
  }


}
