import {Content} from "./content";

export class Subscription{
  id: string;
  name: string;
  description: string;
  subtitle: string;
  price: number;
  duration: string;
  promoPrice: number;
  promoPeriod: string;

  constructor(result: any = {}) {
    this.id = result.id;
    this.name = result.name;
    this.description = result.description;
    switch (result.duration) {
      case 'monthly':
        this.duration = 'mes';
        break;
      case 'yearly':
        this.duration = 'año';
        break;
      default:
        this.duration = result.duration;
        break;
    }
    this.subtitle = result.subtitle;
    this.price = result.price;
    this.promoPrice = result.promoPrice;
    this.promoPeriod = result.promoPeriod;

  }

}
