import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Course } from '../model/course';
import { Content } from '../model/content';
import { map, tap } from 'rxjs/operators';
import { User } from '../model/user.model';
import { StateCourse } from '../model/state.model';
import { Event } from '../model/event.model';
import { Subscription } from '../model/subscription.model';
import { AuthenticationService } from './authentication.service';

const ACCESS_TOKEN_KEY = 'my-access-token';
interface SupportTicket {
  title: string;
  text: string;
  contactEmail: string;
  phoneNumber: string;
}
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  url = environment.apiUrl;
  profile = null;

  courses: Course[] = null;
  token: string;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, private router: Router) {
    //  console.log('window.localStorage.getItem(ACCESS_TOKEN_KEY)', window.localStorage.getItem(ACCESS_TOKEN_KEY));
    //this.profile = this.getSecretData();
    //this.loadCourses().subscribe(courses => {this.courses = courses;});
    // this.loadUser().subscribe(user => this.user = user);
  }

  loadCourses(): Observable<Course[]> {
    return this.http.get<Course[]>(`${this.url}/courses`).pipe(
      map((results) => results.map((result) => new Course(result))),
      tap((courses) => {
        this.courses = courses;
        // console.log("LoadCourses: ", this.courses);
      })
    );
  }

  getCourses(state): Observable<Course[]> {
    if (state == 'playerBackState') {
      return this.loadCourses();
    }

    if (this.courses !== null) {
      // console.log('1');

      return new Observable<Course[]>((observer) => {
        observer.next(this.courses);
        observer.complete();
      });
    } else {
      // console.log('2');

      return this.loadCourses();
    }
  }

  async getCourse(id: string): Promise<Course> {
    // console.log("Searching", id,this.courses);
    if (this.courses !== null) {
      return this.courses.find((course) => course.id === id);
    } else {
      return this.loadCourses()
        .toPromise()
        .then((courses) => courses.find((course) => course.id === id));
    }
  }

  loadUser(): Observable<User> {
    return this.http.get<User>(`${this.url}/user`).pipe(
      tap((user) => {
        if (!user.states) {
          console.error('xaxa States array is missing from user data!');
        }
      })
    );
  }

  loadSubscriptions(): Observable<Subscription[]> {
    return this.http
      .get<Subscription[]>(`${this.url}/subscription`)
      .pipe(
        map((results) => results.map((result) => new Subscription(result)))
      );
  }
  updateProfile(user: {
    name?: string;
    newPassword?: string;
    currentPassword?: string;
    type: 'info' | 'picture';
  }): Observable<any> {
    return this.http
      .post<any>(`${this.url}/user/update-profile`, user, this.httpOptions);
  }

  uploadImage(user: {
    format: string;
    image: string;
    type: 'info' | 'picture';
  }): Observable<any> {
    return this.http
      .post<any>(`${this.url}/user/update-profile`, user, this.httpOptions);
  }

  deleteProfile(): Observable<any> {
    return this.http
      .post<any>(`${this.url}/user/delete-profile`, this.httpOptions);
  }

  confirmDeleteProfile(token: string): Observable<any> {
    return this.http
      .post<any>(`${this.url}/user/confirm-delete/${token}`, this.httpOptions);
  }
  postSupport(ticket: SupportTicket): Observable<any> {
    const requestBody = {
      title: ticket.title,
      text: ticket.text,
      contactEmail: ticket.contactEmail,
      phoneNumber: ticket.phoneNumber,
    };

    return this.http.post<any>(
      `${this.url}/support`,
      requestBody,
      this.httpOptions
    );
  }

  postEvent(state: StateCourse, event: Event): Observable<any> {
    return this.http.post<any>(
      `${this.url}/user/event`,
      {
        event: event.type,
        courseId: state.course.id,
        contentId: state.content.id,
        seconds: event.seconds,
        answer: event.answer,
      },
      this.httpOptions
    );
  }

  
  // getAlerts(){
  //   return this.http.get<Alert[]>(`${this.url}/profile/alerts`);
  //   /*.subscribe((data: any)=>{
  //       data.forEach(element => this.alerts.push(Alert.create(element)));
  //     });*/
  // }
  // getHoles(startTime: Date,endTime: Date){
  //   let start = startTime.getUTCDate() + "-" + (startTime.getUTCMonth()+1) + "-" + startTime.getUTCFullYear();
  //   let end = endTime.getUTCDate() + "-" + (endTime.getUTCMonth()+1) + "-" + endTime.getUTCFullYear();
  //   return this.http.get(`${this.url}/services/calendar?s=`+start+"&e="+end);
  // }
  //
  // getServiceDetails(serviceId){
  //   return this.http.get(`${this.url}/services/`+serviceId);
  // }
  orderUpdate(orderId: any) {
    return this.http.get(`${this.url}/user/order/` + orderId, this.httpOptions);
  }

  orderCreate(amount: number) {
    const data = {
      amount,
      currency: 'EUR',
    };
    return this.http.put<any>(`${this.url}/user/order`, data, this.httpOptions);
  }

  deleteAccount() {
    try {
      // return this.http.delete(`${this.url}/user/${id}/delete`, this.httpOptions);
      return this.http.delete(`${this.url}/user/delete`, this.httpOptions);
    } catch (error) {
      console.warn({ error });
    }
  }
  sendAnalytics(data: any): Observable<any> {
    return this.http.post<any>(`${this.url}/analytics`, data, this.httpOptions);
  }
  getVersion(): Observable<any> {
    return this.http.get<any>(`${this.url}/version`);
  }

  subscriptionCancel() {
    const userData = {
      subscribed: false,
    };
    return this.http.put(
      `${this.url}/user/subscription_cancel`,
      userData,
      this.httpOptions
    );
  }
}
``;
