<ion-button 
  [class.full-size-button]="expand"
  [class.fixed-size-button]="!expand"

  size="{{ size }}"
  shape="round"
  fill="{{
    mode === 'primary' ? 'solid' : mode === 'text' ? 'text' : 'outline'
  }}"
  color= {{getBtnColor()}}
  type="button"
  [disabled]="disable"
  (click)="onClick()"
>
  <ion-text   color= {{getTxtColor()}} *ngIf="!loading" class="custom-button-text">{{ text }}</ion-text>
  <p *ngIf="!!loading && mode==='text'" class="custom-button-text"> Loading... </p>

  <ion-icon
    *ngIf="!!iconName && mode !== 'text' && !loading"
    name="{{ iconName }}"
    slot="{{ iconSlot }}"
  ></ion-icon>

  <ion-spinner
    [attr.color]="mode !== 'primary' ? 'dark' : null"
    *ngIf="loading && mode !== 'text'"
    name="circular"
  >

  </ion-spinner>
</ion-button>
