<ion-content>
  <div class="title">
    <h1>Hackea tus emociones </h1>
    <ion-icon name="close" slot="icon-only" (click)="dismiss()"></ion-icon>
  </div>

  <ion-list>
    <ion-item>
      <ion-icon name="checkmark-circle"></ion-icon>
      <ion-label>Desbloquea la experiencia completa</ion-label>
    </ion-item>
    <ion-item>
      <ion-icon name="checkmark-circle"></ion-icon>
      <ion-label>Descargar cursos sin conexión</ion-label>
    </ion-item>
    <ion-item>
      <ion-icon name="checkmark-circle"></ion-icon>
      <ion-label>Notificaciones y recordatorios</ion-label>
    </ion-item>
  </ion-list>

  <div *ngIf="this.subscriptionService.subscriptions === null then blockLoading else blockSubscriptions"></div>
    <ng-template #blockLoading>
      <div class="suscriptions">
        <h1>Cargando...</h1>
      </div>
    </ng-template>

    <ng-template #blockSubscriptions>
      <div class="suscriptions">
      <ng-container *ngFor="let subscription of this.subscriptionService.subscriptions first as isFirst">
        <div id="subscription_{{subscription.id}}" class="suscription" (click)="selectType(subscription.id)" [ngClass]="{'purple': isFirst === true}">
          <p *ngIf="subscription.promoPrice > 0 then blockOffer else blockNormal"></p>
          <ng-template #blockNormal>
            <span class="price">{{subscription.price}}€</span> por {{subscription.duration}}
          </ng-template>
          <ng-template #blockOffer>
            <span class="price withoutOffer">{{subscription.price}}€</span><span class="price">{{subscription.promoPrice}}€</span> por {{subscription.duration}}
          </ng-template>
          <p class="promo">{{subscription.subtitle}}</p>
        </div>
      </ng-container>
      </div>
    </ng-template>
<!--    <div id="subscription_monthly" class="suscription purple" (click)="selectType('monthly')">-->
<!--      <p><span class="price">2,99€</span> por mes</p>-->
<!--      <p class="promo">Primera semana gratis</p>-->
<!--    </div>-->

<!--    <div id="subscription_anual" class="suscription" (click)="selectType('anual')">-->
<!--      <p><span class="price">49€</span> al año</p>-->
<!--      <p class="promo">Ahorra hasta 15€</p>-->
<!--    </div>-->

<!--  </div>-->

</ion-content>

<ion-footer>
  <ion-button (click)="subscribe()">Pruébalo gratis</ion-button>
 <!-- <ion-button class="white" (click)="dismiss()">Ahora no</ion-button> -->
</ion-footer>
