<div class="card ion-activatable " (click)="onclick() ">
  <ion-ripple-effect></ion-ripple-effect>

  <img src="../../../assets/heart in chat.svg" alt="Profile Image" class="image">
  <div class="content ">

      <div class="title">¿Quieres hablar?</div>
      <div class="subtitle">Accede al chat</div>
  </div>
  <!-- Ionicon inside a rounded button -->

    <ion-icon name="chevron-forward-outline" size="large" class="icon"></ion-icon>

</div>