<div *ngIf="!loading && continue" class="card-continue ion-activatable ">
  <ion-ripple-effect></ion-ripple-effect>
  <img src="{{ imageURL }}" alt="Profile Image" class="image-continue" />
  <div class="test-continue">
    <div class="content-continue">
      <span class="text2-continue">{{ course }}</span>

      <div class="title-continue">{{ content }}</div>

      <span class="text2-continue">{{ time }}</span>
      <div *ngIf="progress !== 0" class="progress-bar-empty-continue"></div>
      <div
        *ngIf="progress !== 0"
        class="progress-bar-full-continue"
        [style.width.%]="progress"
      ></div>
    </div>
    <div class="icon-button-continue">
      <ion-icon name="play"></ion-icon>
    </div>
  </div>
</div>



<div  *ngIf="!loading && !continue"
  class="card-content  ion-activatable"
  [class.selected-content]="selected"
  [class.not-selected-content]="!selected"
>
<ion-ripple-effect ></ion-ripple-effect>
  <div class="content-content">
    <div class="title-content">{{ content }}</div>
    <div class="subtitle-content">
      <ion-icon name="volume-high"></ion-icon>
      <span class="duration-content">{{ time }}</span>
    </div>
  </div>

  <button *ngIf="locked" class="icon-button-lock-content">
    <ion-icon name="lock-closed"></ion-icon>
  </button>
  <button
    *ngIf="!locked"
    [class.icon-button-selected-content]="selected"
    [class.icon-button-not-selected-content]="!selected"
  >
    <ion-icon name="play"></ion-icon>
  </button>
  <div *ngIf="progress !== 0" class="progress-bar-empty-content"></div>
  <div
    *ngIf="progress !== 0"
    class="progress-bar-full-content"
    [style.width]="computedWidth"
  ></div>
</div>



<div *ngIf="loading && continue" class="card-continue">
  <div class="static-image-container">
    <div class="static-content"></div>
  </div>
  <!-- <img
    src="../../../assets\Frame 7331.svg"
    alt="Course Image"
    class="image-continue"
  /> -->
  <div class="test-continue">
    <div class="content-continue">
      <ion-skeleton-text
        [animated]="true"
        style="width: 30%; height: 20px"
      ></ion-skeleton-text>
      <ion-skeleton-text
        [animated]="true"
        style="width: 80%; height: 20px"
      ></ion-skeleton-text>

      <ion-skeleton-text
        [animated]="true"
        style="width: 20%; height: 20px"
      ></ion-skeleton-text>


    </div>
  </div>
</div>



<div *ngIf="loading && !continue" class="card-content not-selected-content">
  <div class="content-content">
    <ion-skeleton-text
      [animated]="true"
      style="width: 80%; height: 20px"
    ></ion-skeleton-text>
    <ion-skeleton-text
      [animated]="true"
      style="width: 30%; height: 20px"
    ></ion-skeleton-text>
  </div>

  <button class="icon-button-not-selected-content">
    <ion-icon name="play"></ion-icon>
  </button>
</div>