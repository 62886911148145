import { EventEmitter, Injectable } from '@angular/core';
import { User } from '../model/user.model';
import { ApiService } from './api.service';
import { Course } from '../model/course';
import { StateCourse } from '../model/state.model';
import { StateContent } from '../model/stateContent.model';
import { Event } from '../model/event.model';
import { AuthenticationService } from './authentication.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: User = null;
  userUpdated = new EventEmitter<User>();
  constructor(
    private api: ApiService,
    private router: Router,
    private auth: AuthenticationService,
    private alertController: AlertController // private ga: GoogleAnalytics,
  ) {
    // this.init();
  }

  init(): void {
    // console.log("Init UserService");
    // return this.api.loadUser().toPromise().then(
    //   (user: any) => {
    //     this.user = this.mapUserJson(user);
    //     this.user.states.sort((a,b) => b.dateLastListen.getTime() - a.dateLastListen.getTime());
    //     console.log('User Init',this.user);
    //   }
    // );
    // return this.api.loadUser().pipe(
    //   map(
    //     (user: any) => {
    //       this.user = this.mapUserJson(user);
    //       this.user.states.sort((a,b) => b.dateLastListen.getTime() - a.dateLastListen.getTime());
    //       console.log('User Init',this.user);
    //       return this.user;
    //     }
    //   )
    // );
    this.getUser('').subscribe((user: User) => {
      // console.log('User Initialized', user);
      this.user = user;
      this.user.states = this.user.states.sort(
        (a, b) => b.dateLastListen.getTime() - a.dateLastListen.getTime()
      );
      // console.log('User Initialized', this.user);
    });
  }

  getUser(state, forceRefresh = false): Observable<User> {
    // If user and user.states already exist, return them.
    if (this.user && this.user.states && !forceRefresh) {
      return of(this.user); // Using 'of' to return an observable of a single value
    }
    // Otherwise, fetch courses first, then load the user.
    return this.api.getCourses('').pipe(
      switchMap((courses: Course[]) =>
        this.api.loadUser().pipe(
          map((result) => this.mapUserJson(result, courses)),
          tap((user) => {
            this.user = user;
            // console.log(user);
          })
        )
      )
    );
  }

  /**
   * Get the state of a certain course
   * If create is true, the state will be created if it doesn't exist
   *
   * @param course
   * @param create
   * @returns
   */
  async getStateCourse(
    course: Course,
    create: boolean = false
  ): Promise<StateCourse> {
    return this.getUser('')
      .pipe(
        map((user: User) => {
          const state = user.states.find((x) => x.course.id === course.id);
          if (!state && create) {
            user.states.push(new StateCourse(course));
            return this.getStateCourse(course, false);
          }
          return state;
        })
      )
      .toPromise();
  }

  async getStateCourseAgain(
    course: Course,
    create: boolean = false
  ): Promise<StateCourse> {
    return this.getUser('playerBackState')
      .pipe(
        map((user: User) => {
          const state = user.states.find((x) => x.course.id === course.id);
          if (!state && create) {
            user.states.push(new StateCourse(course));
            return this.getStateCourse(course, false);
          }
          return state;
        })
      )
      .toPromise();
  }

  getLastState(): StateCourse {
    return this.user.states[0];
  }

  saveEvent(state: StateCourse, event: Event) {
    // console.log('Save', state, event);
    this.api
      .postEvent(state, event)
      .toPromise()
      .then((res: any) => {
        // console.log('Save event', res);
      });
  }

  isCourseActive(course: Course): Observable<boolean> {
    return this.getUser('').pipe(
      switchMap((user) => {
        if (user.states.find((x) => x.course.id === course.id)) {
          return of(true);
        } else {
          return of(false);
        }
      })
    );
  }

  getName(): string {
    return this.user.name;
  }
  setName(name: string): void {
    this.user.name = name;
    this.userUpdated.emit(this.user);
  }
  getEmail(): string {
    return this.user.email;
  }

  logout() {
    this.auth.logout();
  }
  async confirmLogout() {
    const alert = await this.alertController.create({
      header: 'Confirmar cierre de sesión',
      message: '¿Estás seguro de que deseas cerrar la sesión?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Sí',
          handler: () => {
            this.logout();
            this.router.navigate(['/log-in']);
          },
        },
      ],
    });

    await alert.present();
  }

  async confirmDeleteAccount() {
    const alert = await this.alertController.create({
      header: 'Confirmar eliminación de cuenta', // Adjusted header text
      message: '¿Estás seguro de que deseas eliminar tu cuenta?', // Adjusted message text
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Sí',
          handler: () => {
            this.deleteProfile();
          },
        },
      ],
    });

    await alert.present();
  }

  deleteProfile() {
    // Puedes incluir un indicador de carga aquí si es necesario

    // Paso 1: Enviar un correo electrónico para confirmación
    this.api.deleteProfile().subscribe(
      async (emailConfirmationRes) => {
        // Descomenta la siguiente línea si tienes un indicador de carga
        // await loading.dismiss();

        // Paso 2: Si el correo electrónico se envía correctamente, muestra una alerta de confirmación
        const alert = await this.alertController.create({
          header: 'Correo de Confirmación Enviado',
          message: `Se ha enviado un correo electrónico para confirmar la eliminación de
           la cuenta. Por favor, revisa tu bandeja de entrada.`,
          buttons: ['OK'],
        });

        await alert.present();
      },
      async (emailConfirmationError) => {
        // Descomenta la siguiente línea si tienes un indicador de carga
        // await loading.dismiss();

        const alert = await this.alertController.create({
          header: 'Error al Enviar el Correo de Confirmación',
          message:
            'Hubo un error al enviar el correo de confirmación. Por favor, inténtalo de nuevo más tarde.',
          buttons: ['OK'],
        });

        await alert.present();
      }
    );
  }

  isSubscribed() {
    if (this.user === null) {
      return false;
    }

    return this.user.subscribed;
  }

  async subscriptionCancel() {
    this.api.subscriptionCancel().subscribe(
      async (res: any) => {
        // console.log({res});
        const alert = await this.alertController.create({
          header: 'Suscripción está cancelada',
          message: res.message,
          buttons: [
            {
              text: 'Confirmar',
              handler: () => {
                window.location.reload();
              },
            },
          ],
        });
        await alert.present();
      },
      async (err: any) => {
        // console.log({err});
        const alert = await this.alertController.create({
          header: 'Algo salió mal',
          message: err.message,
          buttons: ['Ok'],
        });
        await alert.present();
      }
    );
  }

  deleteAccount() {
    this.api.deleteAccount().subscribe(
      async (res: any) => {
        // console.log('Delete account', res);
        const alert = await this.alertController.create({
          header: 'cuenta borrada',
          message: res.message,
          buttons: [
            {
              text: 'Confirmar',
              handler: () => {
                this.logout();
              },
            },
          ],
        });
        await alert.present();
      },
      async (err: any) => {
        // console.log({err});
        const alert = await this.alertController.create({
          header: 'Algo salió mal',
          message: err.error,
          buttons: ['Ok'],
        });
        await alert.present();
      }
    );
  }

  private mapUserJson(json: any, courses: Course[]): User {
    const user = new User();
    user.id = json.id;
    user.name = json.name;
    user.email = json.email;
    user.subscribed = json.isSubscribed;
    user.profilePicture = json.profilePicture;
    user.states = [];
    json.states.forEach((x) => {
      const course = courses.find((y) => y.id === x.courseId);
      if (course) {
        const state = new StateCourse(course);
        state.lastContent = x.lastContent;
        state.dateLastListen = new Date(x.dateLastListen);
        x.contentsState.forEach((y) => {
          const contentState = new StateContent(
            y.contentId,
            y.ended,
            y.updatedAt,
            y.seconds
          );
          state.contentsState.push(contentState);
        });
        user.states.push(state);
      }
    });
    this.user = user;

    return this.user;
  }
}
