import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Keyboard } from '@ionic-native/keyboard/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { PlayerService } from './services/player.service';
import { UserService } from './services/user.service';
import { StateService } from './services/state.service';
import { CaleidaApiService } from './services/caleidaApiService.service';


import { SubscribeModule } from './modal/subscribe/subscribe.module';
import { SettingsModule } from './modal/settings/settings.module';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';

import { RecoverModule } from './components/recover/recover.module';
import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { customAnimation } from './animations/custom.animations';
import { ExternalLoginGuard } from './guards/external-login.guard';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { TabsPageModule } from './tabs/tabs.module';
import { NewVersionModalComponent } from './modal/new-version-modal/new-version-modal.component';
import { ContentNotAvailableModule } from './modal/content_not_available/content-not-available.module';

@NgModule({
  declarations: [AppComponent, NewVersionModalComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ navAnimation: customAnimation }),
    AppRoutingModule,
    HttpClientModule,
    SubscribeModule,
    ContentNotAvailableModule,
    SettingsModule,
    RecoverModule,
    TabsPageModule,
  ],
  providers: [
    Keyboard,
    Media,
    UserService,
    StateService,
    PlayerService,
    CaleidaApiService,
    Deeplinks,
    MusicControls,
    InAppPurchase2,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
